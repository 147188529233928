import React from 'react'
import "./Loader.css";

export const Loader = () => {
  return (
    <div className="loading">
    <div></div>
  </div>
  )
}
