export const rocketLeagueOptions = [
    {label: "1", value: "1v1: 1 Game", disabled: false},
    {label: "2", value: "1v1: Best of 3 Games", disabled: false},
    {label: "3", value: "1v1: Best of 5 Games", disabled: false},
    {label: "4", value: "1v1: Best of 7 Games", disabled: false},
    {label: "5", value: "First to score 3 goals", disabled: true},
    {label: "6", value: "First to score 5 goals", disabled: true},
    {label: "7", value: "First to score 7 goals", disabled: true},
    ]

export const callOfDutyOptions = [
    {label: "1", value: "1v1: Best of 3 Games"},
    {label: "2", value: "1v1: Best of 5 Games"},
    {label: "3", value: "1v1: Best of 7 Games"},
    {label: "4", value: "First to get 5 kills"},
    {label: "5", value: "First to get 10 kills"},
    {label: "6", value: "First to get 20 kills"}
  ]

export const counterStrikeOptions = [
    {label: "1", value: "1v1: Best of 3 Games"},
    {label: "2", value: "1v1: Best of 5 Games"},
    {label: "3", value: "1v1: Best of 7 Games"},
    {label: "4", value: "First to get 5 kills"},
    {label: "5", value: "First to get 10 kills"},
    {label: "6", value: "First to get 20 kills"}
  ]

export const purchaseData = [
  {
    name:"Generic",
    level:"Level 1",
    price:"$100",
    additional:"[1/200]",
    sources:[{type:"video",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/Generic+Original/1.mp4"},{type:"image",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/Generic+Original/2.png"},{type:"image",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/Generic+Original/3.gif"}]
  },
  {
    name:"Taric",
    level:"Level 1",
    price:"$100",
    additional:"[1/200]",
    sources:[{type:"video",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/Taric/1.mp4"},{type:"image",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/Taric/2.png"},{type:"image",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/Taric/3.png"}]
  },
  {
    name:"Ninja",
    level:"Level 2",
    price:"$250",
    additional:"[1/50]",
    sources:[{type:"video",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/Ninja/1.mp4"},{type:"video",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/Ninja/4.mp4"},{type:"image",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/Ninja/2.png"}]
  },
  {
    name:"Disrespect",
    level:"Level 3",
    price:"$350",
    additional:"[1/15]",
    sources:[{type:"video",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/Disrespect/1.mp4"},{type:"image",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/Disrespect/2.png"},{type:"image",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/Disrespect/3.png"}]
  },
  {
    name:"99Bandits",
    level:"Level 4",
    price:"N/A",
    additional:"[1/15]",
    sources:[{type:"video",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/99Bandits/1.mp4"},{type:"image",link:"https://duelcards-prod.s3.us-west-2.amazonaws.com/99Bandits/2.png"}]
  },
]

export const findSource = (name)=>{
  return purchaseData.find((item)=>item.name === name)
}