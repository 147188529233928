import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import styles from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../actions/userAction";
import { Loader } from "../component/Loader";
import Resizer from "react-image-file-resizer";
import img1 from "../images/Profile.png";
import logo from "../images/new-logo-2.png"
import { errorToast, infoToast } from "../constants/ToastNotifications";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createStyles} from '@mui/styles';
import { createTheme,ThemeProvider} from '@mui/material/styles';
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";
import Avatar from "@material-ui/core/Avatar";
import Select from 'react-select';
import countrylist from '../country-states.json';
import "../Pages/Profile.css";
export const Register = () => {
 
  const { loading, isAuthenticated, user, error } = useSelector(
    (state) => state.user
  );
  
  const [registerloading,setRegisterLoading] = useState(false)
  const dispatch = useDispatch();
  const selectInputRef = useRef();
  // const alert = useAlert();
  const navigate = useNavigate();
  const [inputvalue, setInputvalue] = useState({
    username: "",
    email: "",
    firstname: "",
    lastname:"",
    dateOfBirth: "",
    password: "",
    avatar: "",
    referralCode: "",
  });

  const [selectedimage, setSelectedImage] = useState([]);
  const [avtarpreview, setAvatarpreview] = useState(img1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectstate, setSelectState] = useState(null);
  const [stateList, setStateList] = useState([]);
  const useStyles = makeStyles((theme) =>
  createStyles({
   
    datePickerContainer: {
      marginTop: "0.6rem",
      marginBottom: '0.6rem', // Apply margin-top to the container
    },
    datePicker: {
      height:"45px",
      width: "100%",
	    borderRadius: "10px",
	    backgroundColor: "#dee2e6",
	    margin: "5px 0",
      fontSize: "14px",
	    color: "#1b193c",
    '& .MuiOutlinedInput-root': {
    border: 'none',
    
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-input': {
    color: "gray",
    fontSize:"14px",
    textTransform:'lowercase',
  },
 
    },
    tabletStyler:{
      width:"100%",
      height:"45px",
      borderRadius: "10px",
	    backgroundColor: "#dee2e6",
	    margin: "5px 0",
      fontSize: "14px",
	    color: "#1b193c",
      '& .MuiOutlinedInput-root': {
        border: 'none',
        
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputBase-input': {
        color: "gray",
        fontSize:"14px",
        textTransform:'lowercase',
      },
    },
    mobileStyler:{
      width:"250px",
      maxWidth:"100%",
      height:"45px",
	    borderRadius: "10px",
	    backgroundColor: "#dee2e6",
	    margin: "5px 0",
      fontSize: "14px",
	    color: "#1b193c",
      '& .MuiOutlinedInput-root': {
        border: 'none',
        
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputBase-input': {
        color: "gray",
        fontSize:"14px",
        textTransform:'lowercase',
      },
    }
  })
);


  const classes = useStyles();
  const tabletsize=useMediaQuery('(max-width:558px)');
  const mobilesize=useMediaQuery('(max-width:310px)')
  const handleChange = async (event) => {
    const { name, value } = event.target;
    setInputvalue({ ...inputvalue, [name]: value });
  
    console.log(inputvalue);
  };
  const handlecheckusername = async () => {
    const { data } = await axios.post(`/api/auth/checkUsername`, {username:inputvalue.username});
    if(!data.success && data?.message){
      errorToast(data?.message);
      setRegisterLoading(false)
      return;
    }
  }
  const validateAge = (dateString) => {
    const today = new Date();
    const selectedDate = new Date(dateString);
    let age = today.getFullYear() - selectedDate.getFullYear();
    const monthDiff = today.getMonth() - selectedDate.getMonth();
  
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < selectedDate.getDate())) {
      age--;
    }
  
    return age >= 18;
  };

  const handleimage = (e) => {
    console.log(e.target.files);
    setSelectedImage(e.target.files);

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        // setavatarPreview(reader.result);
        setAvatarpreview(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };
  const handleCountry = (value) => {
    setSelectState(null);
    setSelectedOption(value.value);
    setStateList(() => {
      return countrylist.countries.find(item => item.country === value.value).states
    })
  }
  const handleState = (value) => {
    if(value.value == "Arizona"  || value.value == "Arkansas" || value.value ==  "Connecticut" || value.value == "Delaware"|| value.value == "Florida"|| value.value ==  "Indiana"|| value.value == "Louisiana" || value.value == "Maryland" || value.value == "Minnesota" ||value.value ==  "Montana" || value.value == "South Carolina"|| value.value ==  "South Dakota" || value.value == "Tennessee" || value.value ==  "Wyoming"){
      setSelectState(null);
      errorToast("We regret for informing you that our services are not available in your state.")
      selectInputRef.current.select.clearValue();      
    } else {
      setSelectState(value.value);
    }
    
  }
  // const encodefile = (file) => {
  //   if (file) {
  //     try {
  //       Resizer.imageFileResizer(
  //         file,
  //         300,
  //         300,
  //         "jpg",
  //         100,
  //         0,
  //         (uri) => {
  //           inputvalue.avatar = uri;
  //         },
  //         "base64",
  //         200,
  //         200
  //       );
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };
  // encodefile(selectedimage[0]);

  const handleSubmit = async (e) => {
    setRegisterLoading(true)
    e.preventDefault();
    const Date=inputvalue.dateOfBirth.$D;
    const month=inputvalue.dateOfBirth.$M;
    const year=inputvalue.dateOfBirth.$y;
    const exactMonth=month+1;
    const formattedDate=`${year}-${exactMonth}-${Date}`;
    console.log(formattedDate);
    const myForm = new FormData();
    myForm.set("username", inputvalue.username);
    myForm.set("email", inputvalue.email);
    myForm.set("firstname", inputvalue.firstname);
    myForm.set("lastname", inputvalue.lastname);
    myForm.set("dateOfBirth", formattedDate);
    myForm.set("password", inputvalue.password);
    myForm.set("referralCode", inputvalue.referralCode);
    
    console.log(inputvalue.dateOfBirth);
    

    if(!inputvalue.username || inputvalue.username == ""){
      errorToast("Please enter your username");
      setRegisterLoading(false)
      return;
    } 
    if(!inputvalue.email || inputvalue.email == ""){
      errorToast("Please enter your email");
      setRegisterLoading(false)
      return;
    } else if ( inputvalue.email){
      const validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!inputvalue.email.match(validRegex)) {
        errorToast("Please enter valid email address");
        setRegisterLoading(false)
        return;
      }
    }
    if(!inputvalue.firstname || inputvalue.firstname == ""){
      errorToast("Please enter your firstname");
      setRegisterLoading(false)
      return;
    } 
    if(!inputvalue.lastname || inputvalue.lastname == ""){
      errorToast("Please enter your lastname");
      setRegisterLoading(false)
      return;
    } 

    if(!Date || !exactMonth || !year){
      errorToast("Please select your birthday");
      setRegisterLoading(false)
      return;
    }
    const isAbove18 = validateAge(inputvalue.dateOfBirth);
    console.log(isAbove18);
    if(formattedDate && !isAbove18){
      errorToast("You must be at least 18 years old to participate on Gamewagrs.com")
      console.log("less than 18 years.");
      setRegisterLoading(false)
      return;
    }
    /*if(!inputvalue.phoneNumber || inputvalue.phoneNumber == ""){
      errorToast("Please enter your Phone Number");
      setRegisterLoading(false)
      return;
    }
    const validphoneRegex = /^\+?\d{0,14}$|^\d{1,15}$/
    if(!inputvalue.phoneNumber.match(validphoneRegex)){
      errorToast("Please enter valid Phone Number");
      setRegisterLoading(false)
      return;
    }*/
    // if(!inputvalue.address || inputvalue.address == ""){
    //   errorToast("Please enter your address");
    //   setRegisterLoading(false)
    //   return;
    // } 

    if(!selectedOption || selectedOption == null){
      errorToast("Please select your country");
      setRegisterLoading(false)
      return;
    }
    if(!selectstate || selectstate == null){
      errorToast("Please select your state");
      setRegisterLoading(false)
      return;
    }
    /*if(!inputvalue.city || inputvalue.city == ""){
      errorToast("Please enter your city");
      setRegisterLoading(false)
      return;
    }*/
    //logo s3 upload
    
    if(!inputvalue.password || inputvalue.password == ""){
      errorToast("Please enter your password");
      setRegisterLoading(false)
      return;
    } else if(inputvalue.password.length < 6){
      console.log(inputvalue.password.length);
      errorToast("Password must be 6 characters long!!");
      setRegisterLoading(false)
      return;
    }
    const formData = new FormData()
    formData.append('myImage', selectedimage[0])

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    if (selectedimage[0] && selectedimage[0].size >= 1000000) {
      errorToast("Profile picture must be less than 1mb! Please select a smaller image!");
      setRegisterLoading(false)
      return;
    }

    const {data} = await axios.post('/api/auth/avatar', formData, config)
   
    if(data.imageName){
      setRegisterLoading(false)
      dispatch(register({...inputvalue,dateOfBirth:formattedDate,avatar:data.imageName,country: selectedOption,state: selectstate}, navigate, infoToast));
    } else{
      setRegisterLoading(false)
      dispatch(register({...inputvalue,dateOfBirth:formattedDate,avatar:img1.slice(14),country: selectedOption,state: selectstate}, navigate, infoToast));
    }

    // setInputvalue({
    //   avatar: "",
    // });

    // if (error) {
    //   errorToast(error.message);
    // }
  };
  useEffect(() => {
    if (error) {
      errorToast(error.message);
    }
  }, [error, isAuthenticated, navigate]);
  
  if (localStorage.getItem("nftuser")) {
    navigate("/ChallengeSomeone");
  }

  return (
    <>
      {/* {loading ? (
        <Loader />
      ) : ( */}
        <>
          <div className="body-main pad-bot">
          <Container>
            <div className={styles.signup_container}>
              <div className={styles.signup_form_container}>
                <div className={styles.left}>
                  <div className={styles.left_inner}>
                    <img src={logo} alt="Logo"/>
                  </div>
                </div>
                <div className={styles.right}>
                  <form
                    className={styles.form_container}
                    onSubmit={handleSubmit}
                  >
                    <h2>Create Account</h2>
                    <div className={styles.form_field}>
                    <label htmlFor="username" className={styles.register_form}>Username</label>
                    <div className={styles.input_wrapper}>
                    <input
                      type="name"
                      placeholder="Username"
                      id="username"
                      name="username"
                      onChange={handleChange}
                      value={inputvalue.username}
                      onBlur={handlecheckusername}
                      className={styles.input}
                      autoComplete="name"
                    />
                    <span className={styles.required_indicator}>*</span>
                    </div>
                    </div>
                    <div className={styles.form_field}>
                    <label htmlFor="address" className={styles.register_form}>Email address</label>
                    <div className={styles.input_wrapper}>
                    <input
                      type="text"
                      placeholder="Email"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={inputvalue.email}     
                      className={styles.input}
                      autoComplete="email"
                    />
                    <span className={styles.required_indicator}>*</span>
                    </div>
                    </div>
                    <div className={styles.form_field}>
                    <label htmlFor="firstname" className={styles.register_form}>First name</label>
                    <div className={styles.input_wrapper}>
                    <input
                      type="name"
                      placeholder="First name"
                      id="firstname"
                      name="firstname"
                      onChange={handleChange}
                      value={inputvalue.firstname}
                      
                      className={styles.input}
                    />
                    <span className={styles.required_indicator}>*</span>
                    </div>
                    </div>
                    <div className={styles.form_field}>
                    <label htmlFor="lastname" className={styles.register_form}>Last name</label>
                    <div className={styles.input_wrapper}>
                    <input
                      type="name"
                      placeholder="Last name"
                      id="lastname"
                      name="lastname"
                      onChange={handleChange}
                      value={inputvalue.lastname}
                      
                      className={styles.input}
                    />
                    <span className={styles.required_indicator}>*</span>
                    </div>
                    </div>
                    <div className={styles.form_field}>
                    <label htmlFor="lastname" className={styles.register_form}>Date Of Birth</label>
                    <div className={styles.input_wrapper}>
                    <div className={classes.datePickerContainer}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker className={mobilesize?classes.mobileStyler:tabletsize?classes.tabletStyler:classes.datePicker} value={inputvalue.dateOfBirth}
                     onChange={(date) =>setInputvalue({ ...inputvalue, dateOfBirth: date })}/>
                    </LocalizationProvider>
                    </div>
                    <span className={styles.required_indicator}>*</span>
                    </div>
                    </div>
                    <div className={styles.form_field}>
                    <label htmlFor="address" className={styles.register_form}>Country</label>
                    <div className={styles.input_wrapper}>
                    <Select
                          className="country-select select-field add-full-width mt-3"
                          placeholder = {"Select country"}
                          defaultValue={selectedOption}
                          // value={{value : selectedOption}}
                          onChange={(value) => { handleCountry(value) }}
                          options={countrylist.countries.map(item => ({ value: item.country, label: item.country }))}
                        />
                        <span className={styles.required_indicator}>*</span>
                        </div>
                    </div>
                    <div className={styles.form_field}>
                    <label htmlFor="city" className={styles.register_form}>State</label>
                    <div className={styles.input_wrapper}>
                        <Select
                          ref={selectInputRef}
                          className="state-select select-field mt-3"
                          placeholder = {"Select state"}
                          defaultValue={selectstate}
                          // value={{label : selectstate}}
                          onChange={(value) => { handleState(value) }}
                          isDisabled={!selectedOption}
                          options={stateList.map(item => ({ value: item, label: item }))}
                        />
                        <span className={styles.required_indicator}>*</span>
                        </div>
                    </div>
                    <div className={styles.form_field}>
                    <label htmlFor="password" className={styles.register_form}>Password</label>
                    <div className={styles.input_wrapper}>
                    <input
                      type="password"
                      placeholder="Password"
                      id="password"
                      name="password"
                      onChange={handleChange}
                      value={inputvalue.password}
                      
                      className={styles.input}
                    />
                    <span className={styles.required_indicator}>*</span>
                    </div>
                    </div>
                    <div className={styles.form_field}>
                    <label htmlFor="referralCode" className={styles.register_form}>Referral Code</label>
                    <div className={styles.input_wrapper}>
                    <input
                      type="text"
                      placeholder="Referral Code"
                      id="referralCode"
                      name="referralCode"
                      onChange={handleChange}
                      value={inputvalue.referralCode}
                      
                      className={styles.input}
                    />

                    </div>
                    </div>
                    <div className={styles.file_input}>
                      <input
                        onChange={handleimage}
                        type="file"
                        id="file"
                        className={styles.file}
                      />
                      <label htmlFor="file">
                        Choose Avatar
                        <Avatar
                          className={styles.avatar}
                          src={avtarpreview}
                          
                        />
                      </label>
                    </div>

                    <button type="submit" className={styles.register_btn}>
                      {registerloading || loading ? <Spinner animation="border" size="sm"/> : "Sign Up"}
                    </button>
                  </form>
                    <div className={styles.right_inner}>
                        <div className={styles.existing_user}>
                        Already a user?
                        <Link to="/login">
                            <button type="link" className={styles.login_link}>
                              Sign In
                            </button>
                          </Link>
                        </div> 
                      </div>
                </div>
              </div>
            </div>
          </Container>
          </div>       
        </>
      {/* )}     */}
    </>
  );
};
