import React from 'react';
import './RegisterSuccess.css';
import {Link} from "react-router-dom"

export const RegisterSuccess = () => {
  return (
    <div className='body-main'>
        <div className='thank-sec'>
            <div className='container'>
                <div className='thnkct'>
                <h2>Thank you for registering with GameWagrs!</h2>
                <h4>Please check your email for registration confirmation</h4>
                   <div className='thankbtn'>
                    <Link to = "/login">
                      <button class="go-home hero-btn">
                      Login
                      </button>
                    </Link>
                   </div>
                </div>
            </div>
        </div>
    </div>
  )
}

// export default RegisterSuccess;
