import React, { useState } from "react";
import { Container, Row, Spinner, Toast } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/new-logo-2.png"
import "./ForgetPassword.css";
import axios from "axios";
import { errorToast, successToast } from "../constants/ToastNotifications";

const ForgetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [loader, setLoader] = useState(false);

    const submitHandler = (e) =>{
        const useremail = e.target.value;
        setEmail(useremail);
        
    }
    const handleSubmit = async(e) => {
        setLoader(true);
        e.preventDefault();
        console.log(email);
        try {
            const res = await axios.post("/api/auth/forgetpassword", {
              email: email,
            });
            if (res) {
              if(res?.data?.message){
                errorToast(res?.data?.message);
                setLoader(false);
                return;
              }
              if(res?.data){
              successToast(res?.data?.data);
              navigate(`/login`);

              }
            } else {
              console.log(res.status);
            }
          } catch (error) {
            errorToast(res?.data?.message);
            return;
          } finally {
            setLoader(false);
          }
          setEmail("");
    }

    return (
        <div className="body-main">
            <div className="login-sec">
                <Container>
                    <div className="forget_container">
                        <div className="forget_form_container">
                            <div className="forget-left">
                                <div className="forget_right_inner">
                                    <img src={logo} alt="Logo"/>
                                </div>
                            </div>
                            <div className="forget_right">
                                <div className="mb-2">
                                    <i class="fa fa-exclamation-circle fa-5x" aria-hidden="true"></i>
                                </div>
                                <form
                                    className="form_container"
                                    onSubmit={handleSubmit}
                                >
                                    <h2 className="text-center">FORGOT PASSWORD</h2>
                                    <label className="forget-label mt-3 text-center">Enter your email and we'll send you a link to reset your password</label>
                                    <input
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    className="input"
                                    required
                                    value={email}
                                    onChange={submitHandler}
                                    />
                                    <button type="submit" className="forget_btn" disabled={loader}>
                                    {loader ? <Spinner animation="border" size="sm"/>: "SUBMIT"}
                                    </button>
                                </form>
                                <div className="forget_login">
                                    <div className="new_user">
                                    <Link to="/login">
                                        <button type="link" className="login_link">
                                        Back to Login
                                        </button>
                                    </Link>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default ForgetPassword;