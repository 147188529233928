import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "./login.module.css";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Spinner, Toast } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login } from "../actions/userAction";
// import { useAlert } from "react-alert";
import { Loader } from "./Loader";
import { toast, ToastContainer } from "react-toastify";
import { errorToast } from "../constants/ToastNotifications";
import logo from "../images/new-logo-2.png"

export const Login = () => {
  const { isAuthenticated, user, error, loading } = useSelector(
    (state) => state.user
  );
  const [inputdata, setInputData] = useState({ email: "", password: "" });
  //   const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = ({ currentTarget: input }) => {
    setInputData({ ...inputdata, [input.name]: input.value });
    console.log(inputdata);
  };
  const toastnotification = async (notes, id) => {
    toast.info(notes, {
      position: "top-right",
      autoClose: 5000,
      toastId: id,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(login(inputdata));
  };

  const verifyUser = async (userId, otp) => {
    const { data } = await axios.get(
      `/api/auth/verifyUser?userId=${userId}&otp=${otp}`
    );
    if (data.success) {
      toastnotification(
        <div style={{ color: "white" }}>
          {"Your account is verified successfully."}
        </div>
      );
      navigate("/VerifyUser", { state: data.user });
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get("userId");
    const otp = queryParams.get("otp");
    if (userId && otp) verifyUser(userId, otp);
  }, [window.location.search]);

  useEffect(() => {
    if (error) {
      errorToast(error);
    }
  }, [error, isAuthenticated, navigate]);

  if (localStorage.getItem("nftuser")) {
    navigate("/ChallengeSomeone");
  }
  return (
    <>


      {loading ? (
        <Loader />
      ) : (
    <>
      <div className="body-main">
        <div className="login-sec">
          <Container>
            <>
                <div className={styles.login_container}>
                  <div className={styles.login_form_container}>
                    <div className={styles.left}>
                      <form
                        className={styles.form_container}
                        onSubmit={handleSubmit}
                      >
                        <h1>Login</h1>
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={handleChange}
                          value={inputdata.email}
                          required
                          className={styles.input}
                          autoComplete="email"
                        />
                        <input
                          type="password"
                          placeholder="Password"
                          name="password"
                          onChange={handleChange}
                          value={inputdata.password}
                          required
                          className={styles.input}
                        />

                          <button type="submit" className={styles.login_btn}>
                            {loading ? <Spinner animation="border" size="sm" /> : "Login"}
                          </button>
                        </form>
                        <div className={styles.left_inner}>
                          <Link to="/forgotpassword">
                            <button type="link" className={styles.register_link}>
                              Forgot Password?
                            </button>
                          </Link>
                        </div>
                        <div className={styles.left_inner_register}>
                          <div className={styles.new_user}>
                            Don&apos;t have an account?
                            <Link to="/register">
                              <button type="link" className={styles.register_link}>
                                {loading ? <Spinner animation="border" size="sm" /> : "Sign Up"}
                              </button>

                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className={styles.right}>
                        <div className={styles.right_inner}>
                          <img src={logo} alt="Logo" />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Container>
            </div>
          </div>
        </>)}
    </>
  );
};
