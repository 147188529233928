import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom"
import store from "./store"
import { Provider } from 'react-redux';
import {positions,transitions,Provider as AlertProvider} from "react-alert"
import AlertTemplate from "react-alert-template-basic"
import { StylesProvider } from "@material-ui/core/styles";
import ScrollToTop from './component/ScrollToTop';
const options = {
  timeout:4000,
  position: positions.BOTTOM_CENTER,
  transition: transitions.FADE,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

<>
<Provider store = {store}>
   <AlertProvider template = {AlertTemplate} {...options}>
    <BrowserRouter>
    <ScrollToTop>
    <StylesProvider injectFirst>
    <App />
   </StylesProvider>
   </ScrollToTop>
    </BrowserRouter>
   </AlertProvider>
 </Provider>
 
</>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
