import axios from "axios";
import {
  CLEAR_ERRORS,
  DETAIL_USER_FAIL,
  DETAIL_USER_REQUEST,
  DETAIL_USER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_USER_FAIL,
  LOGOUT_USER_SUCCESS,
  REGISTER_USER_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFIE_REQUEST,
  UPDATE_PROFIE_SUCCESS,
  ADMIN_LOGIN_REQUEST, ADMIN_LOGIN_SUCCESS, ADMIN_LOGIN_FAIL
} from "../constants/userConstants";
import { successToast } from "../constants/ToastNotifications";

export const login = (userdata) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });
    const { data } = await axios.post(`/api/auth/login`, userdata);

    dispatch({ type: LOGIN_SUCCESS, payload: data.user });
    localStorage.setItem("nftuser", JSON.stringify({ ...data.user, password: "", expire: new Date().getDate() + 7 }))
    console.log(data);

  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error.response.data });
    console.log(error.response);
  }

};
// export const adminlogin = (admindata) =>async(dispatch)=> {
// try {
//   console.log(admindata)
//   dispatch({type:ADMIN_LOGIN_REQUEST});
//   const {dataA} = await axios.post(`/api/auth/adminlogin`, admindata);
// console.log(dataA);
//   dispatch({type: ADMIN_LOGIN_SUCCESS , payload: dataA.user});
//   localStorage.setItem("nftadmin", JSON.stringify({...dataA.user, password:"", expire: new Date().getDate()+7}))
// console.log(dataA, "admin");
// } catch (error) {
//   dispatch({type: ADMIN_LOGIN_FAIL, payload: error.response.dataA})
//   console.log(error.response, "adminerror");
// }
// }



export const register = (userData, navigate, toastnotification) => async (dispatch) => {

  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    // const config = { headers: { "Content-Type": "Application/json" } };

    const { data } = await axios.post(`/api/auth/register`, userData);
    dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user });
    navigate("/registersuccess");
    toastnotification(
      <div style={{ color: "white" }}>{"Please check your email for registration confirmation."}</div>,
    );
    console.log("isReferred",data.isReferred)
    if(data.isReferred){
    toastnotification(
      <div style={{ color: "white" }}>{"Your referral code has been successfully applied, and as a result, your referral will receive a reward"}</div>,
    )
    }
    // localStorage.setItem("nftuser",JSON.stringify({...data.user,password:""}))
    
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error.response.data,
    });
    // console.log(error);
  }
};
export const logout = () => async (dispatch) => {
  try {
    // await axios.get(`/api/auth/logout`);
   console.log("logout action");

    localStorage.removeItem("nftuser");
    dispatch({ type: LOGOUT_USER_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGOUT_USER_FAIL, payload: error.response.data.message });
  }
};
//////////////////loaduser////////////////
export const loaduser = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: DETAIL_USER_REQUEST });
    const { data } = await axios.get(`/api/auth/me`);
    if(!data){
      throw new Error("401");      
    }
    console.log("data.user._id",data.user._id)
    if (data.user.overwolf && localStorage.getItem('nftuser')) {
      if(!data?.user?.isMeReferred && data?.user?.isReferred){
        const { referreduserData } = await axios.post(`/api/auth/referupdatebalme`, {_id: data?.user?._id});
          successToast("Congratulations! Your referral code has successfully met the required conditions, and as a result, you have earned a reward in your wallet.")
      }
      localStorage.setItem("overwolfuser", JSON.stringify({ ...data.user.overwolf, expire: new Date().getDate() + 7 }))
    }
    dispatch({ type: DETAIL_USER_SUCCESS, payload: data.user });
  } catch (error) {
    console.log("loaduser!!", error);
    dispatch({ type: DETAIL_USER_FAIL });
    localStorage.removeItem("nftuser");
    navigate('/login', {replace:true});
  }
};

export const updatePassword = (password) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const data = await axios.put(`/api/auth/update/password`, password, config);

    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};
export const updateprofile = (updatedata) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFIE_REQUEST });
    const config = { headers: { "Content-Type": "multipart/form-data" } };  // const data = await axios.put(
    const data = await axios.put(
      `/api/auth/update/profile`,
      updatedata,
      config
    );
    console.log(updatedata);
    dispatch({ type: UPDATE_PROFIE_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response.data,
    });
  }
};
//////////////////loaduser////////////////

//clearing errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
