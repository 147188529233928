import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import SocialMediaLinks from "../component/SocialMediaLinks";
import  "./Howtoplay.css";

const Howtoplay = () =>{
    const [gif1Playing, setGif1Playing] = useState(false);
    const [gif2Playing, setGif2Playing] = useState(false);
    const [gif3Playing, setGif3Playing] = useState(false);
    const [gif4Playing, setGif4Playing] = useState(false);

  const handleGif1Click = () => {
    setGif1Playing(!gif1Playing);
  };

  const handleGif2Click = () => {
    setGif2Playing(!gif2Playing);
  };
  
  const handleGif3Click = () => {
    setGif3Playing(!gif3Playing);
  };

  const handleGif4Click = () => {
    setGif4Playing(!gif4Playing);
  };

    return  (
    <section className="how-to-play">
        <div className="how-container">
            <div>
            <h2 className="instructions-heading">Sign Up</h2>
            <div className="instructions-content">
                <ol className="content-list">
                    <li>Click on the <Link to="/register">Sign Up</Link> button to create your account</li>
                    <li>Provide the required information, such as your username, email address, age, and password</li>
                    <li>Complete the registration process and verify your email</li>
                </ol>
            </div>
            </div>
            <div className="mt-5">
            <h2 className="instructions-heading">Explore the Platform</h2>
            <div className="instructions-content">
                <ul className="content-unordered-list">
                    <li>Familiarize yourself with the GameWagrs interface.</li>
                    <li>Browse through the available games and popular creators.</li>
                    <li>Discover the different games, challenge options, wager amounts.</li>
                    <li>Sign up for our <Link to="/">Beta Tester program</Link> for access to our Practice Environment and Send challenges risk free!</li>
                </ul>
            </div>
            </div>
            <div className="mt-5">
            <h2 className="instructions-heading">Deposit Funds</h2>
            <div className="instructions-content">
                <ol className="content-list">
                    <li>Navigate to the "Deposit" section of your GameWagrs account.</li>
                    <li>Choose your preferred payment method (e.g., PayPal, credit card, etc.).</li>
                    <li>Follow the prompts to link your payment method and deposit funds into your GameWagrs wallet.</li>
                    <li>Confirm the transaction and wait for the funds to be processed and reflected in your account.</li>
                </ol>
                <div className="center-gif">
                <div className="position-relative">
                    {gif1Playing ? (
                        <img src="./deposit-funds.gif" alt="GIF" onClick={handleGif1Click} />
                    ) : (
                        <>
                        <img src="./deposit-fund.png" alt="img" onClick={handleGif1Click} />
                        <button onClick={handleGif1Click} className="how-to-play-btn"><i class="fa fa-play" aria-hidden="true"></i></button>
                        </>
                    )}
                    
                    </div>
                </div>
            </div>
            </div>
            <div className="mt-5">
            <h2 className="instructions-heading">Find and Join Challenges</h2>
            <div className="instructions-content">
                <ol className="content-list">
                    <li>Browse the list of <Link to="/challengemarketplace">available challenges</Link> to find specific games or creators.</li>
                    <li>Select a challenge that suits your preferences in terms of game, wager amount, and opponent.</li>
                    <li>Read the challenge details, rules, and any additional information provided.</li>
                    <li>Click on the "Accept" button to participate in the challenge.</li>
                    <li>Once both parties have accepted, the challenge begins! Your <Link to="/ChallengeReceived">Challenge Status</Link> updates will update with the each contestants progress as you play!</li>
                </ol>
            </div>
            </div>
            {/* <div className="how-challenge mt-5">
            <div class="scrollmenu" id="instructions-head">
             <div>
                    <h2 className="instructions-two-heading">Individual Challenge(with stream link)</h2>
                    <div className="instructions-content">
                    <img src={gif2Playing ? './with-streamlink.gif' : './with-streamlink.png'} onClick={handleGif2Click}/>
                    </div>
                </div>
                <div>
                    <h2 className="instructions-two-heading">Sending and Receiving a Public Challenge</h2>
                    <div className="instructions-content">
                    <img src={gif3Playing ? './pub-challenge.gif' : './pub-challenge.png'} onClick={handleGif3Click}/>
                    </div>
                </div>
                </div>
            </div> */}
            <div className="mt-5">
                <h2 className="instructions-heading">Individual Challenge(with stream link)</h2>
                <div className="center-gif">
                    <div className="position-relative">
                    {gif2Playing ? (
                        <img src="./with-streamlink.gif" alt="GIF" onClick={handleGif2Click} />
                    ) : (
                        <>
                        <img src="./with-streamlink.png" alt="img" onClick={handleGif2Click} />
                        <button onClick={handleGif2Click} className="how-to-play-btn"><i class="fa fa-play" aria-hidden="true"></i></button>
                        </>
                    )}
                    
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <h2 className="instructions-heading">Sending and Receiving a Public Challenge</h2>
                <div className="center-gif">
                <div className="position-relative">
                    {gif3Playing ? (
                        <img src="./pub-challenge.gif" alt="GIF" onClick={handleGif3Click} />
                    ) : (
                        <>
                        <img src="./pub-challenge.png" alt="img" onClick={handleGif3Click} />
                        <button onClick={handleGif3Click} className="how-to-play-btn"><i class="fa fa-play" aria-hidden="true"></i></button>
                        </>
                    )}
                    
                    </div>
                </div>
            </div>
            <div className="mt-5">
            <h2 className="instructions-heading">Playing the Challenge</h2>
            <div className="instructions-content">
            <ol className="content-list">
                <li>Connect with your opponent according to the agreed-upon game and platform.</li>
                <li>Play the designated game or mode, following the rules outlined in the challenge.</li>
            </ol>
            <p>If a challenger does not require a link to their opponent’s live stream, they MUST download the GameWagrs Validator app from the <a href="https://www.overwolf.com/pages/homepage/">Overwolf Appstore</a> in addition to linking their Overwolf account in their <Link to="/profile">Profile</Link>.</p>
            <div className="center-gif">
            <div className="position-relative">
                    {gif4Playing ? (
                        <img src="./play-challenge.gif" alt="GIF" onClick={handleGif4Click} />
                    ) : (
                        <>
                        <img src="./play-challenge.png" alt="img" onClick={handleGif4Click} />
                        <button onClick={handleGif4Click} className="how-to-play-btn"><i class="fa fa-play" aria-hidden="true"></i></button>
                        </>
                    )}
                    
                    </div>
            </div>
            <ol className="content-list" start="3" style={{paddingTop:"10px"}}>
                <li>Complete the challenge within the specified time frame or match requirements.</li>
            </ol>
            </div>
            </div>
            <div className="mt-5">
            <h2 className="instructions-heading">Report Results and Disputes</h2>
            <div className="instructions-content">
                <ol className="content-list">
                    <li>After completing the challenge, report the results within the GameWagrs platform.</li>
                    <li>If there are any disputes or disagreements, Please contact <a href="mailto:administrator@gamewagrs.com">administrator@gamewagrs.com.</a> or submit a ticket on or <a href="https://discord.com/invite/TzHGjNyACC">discord</a>.</li>
                    <li>We will also be reviewing any live stream link associated with the challenge manually by our moderators</li>
                </ol>
            </div>
            </div>
            <div className="mt-5">
            <h2 className="instructions-heading">Withdraw Winnings</h2>
            <div className="instructions-content">
                <ol className="content-list">
                    <li>If you win a challenge, the winnings will be automatically transferred to your GameWagrs wallet.</li>
                    <li>Navigate to the "Withdraw" or "Cash Out" section of your account.</li>
                    <li>Choose your preferred withdrawal method and follow the prompts to request a withdrawal.</li>
                    <li>Verify your identity if required, and confirm the withdrawal request.</li>
                    <li>Wait for the processing of your withdrawal, which may take some time depending on the chosen method.</li>
                </ol>
            </div>
            </div>
            <div className="mt-5">
            <h2 className="instructions-heading">Engage and Connect</h2>
            <div className="instructions-content">
                <ul className="content-unordered-list">
                    <li>Participate in the GameWagrs community by engaging with other users, creators, and the platform itself.</li>
                    <li>Join discussions, share your achievements, and connect with fellow gamers.</li>
                    <li>Follow GameWagrs on social media or subscribe to newsletters to stay updated on news, promotions, and events.</li>
                </ul>
            </div>
            </div>
               {/* <div className="footer-social-media-wrapper header-icons">
                      <SocialMediaLinks />
                    </div> */}
        </div>
    </section>
    )
}

export default Howtoplay;