import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import "./MonitorFunds.css";
import { Admin } from "./Admin";

export const MonitorFunds = () => {
  const [depositedfund, setDepositedFund] = useState(0);
  const [escrowfunds, setEscrowFunds] = useState(0);
  const [paypalfund, setPaypalFund] = useState(0);

  const getallmonitorfunds = async () => {
    const res = await axios.post("/api/auth/getmonitorfunds");
    setDepositedFund(res.data.deposited_fund)
    setEscrowFunds(res.data.fundsChal)
  }
  const getpaypalmonitor = async () => {
    const paypalres = await axios.post("/api/auth/getpaypalmonitorfund");
    console.log("paypalres", paypalres)
    if (paypalres?.data?.balances?.[0]?.total_balance?.value) {
      setPaypalFund(paypalres?.data?.balances?.[0]?.total_balance?.value)
    } else {
      alert(paypalres.name)
      errorToast(paypalres.message)
    }

  }
  useEffect(() => {
    getallmonitorfunds();
    getpaypalmonitor();
  }, []);

  return (
    <div className="user_content">
      <div className="user_slide">
        <Admin />
      </div>
      <div className="container-fluid  table_A">
        <div className="challenge-heading">
          <h1>Monitor Funds</h1>
          <div className='monitor-card-container'>
            <div className="monitor-card-wrapper">
              <div className="monitor-header-text text-center">Funds Deposited</div>
              <div className="monitor-desc-text"> ${depositedfund?.toFixed(2)}</div>
            </div>
            <div className="monitor-card-wrapper">
              <div className="monitor-header-text text-center">Funds in Escrow</div>
              <div className="monitor-desc-text"> ${escrowfunds?.toFixed(2)}</div>
            </div>
            <div className="monitor-card-wrapper">
              <div className="monitor-header-text text-center">Paypal Account total</div>
              <div className="monitor-desc-text"> ${parseFloat(paypalfund)?.toFixed(2)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonitorFunds;
