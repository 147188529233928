import "./Faq.css";

const UserDataPolicy = () => {
  return (
    <div className="body-main">
      <div className="faq">
        <div className="faq-container">
          <div className='section-title'>
            <h2>User Data Policy</h2>
          </div>
          <div className="faq-main data-policy">
            <div className="">
              <div className="">
                <div className="right-faq-body">
                  At GameWagrs.com, we take your privacy seriously. We are committed to protecting the personal information you share with us and to providing you with transparency about how your information is being used.
                </div>
                <div className="right-faq-head">Data Collection:</div>
                <div className="right-faq-body">
                  We collect the following information from our users:
                </div>
                <div className="right-faq-body">
                  <ul>
                    <li>Usernames on gaming networks</li>
                    <li>Game data and stats provided by Overwolf during gameplay</li>
                    <li>Email address for site notifications</li>
                  </ul>
                </div>   
                <div className="right-faq-head">Use of Data:</div>
                <div className="right-faq-body">
                  We use the data we collect from users to:
                </div>
                <div className="right-faq-body">
                  <ul>
                    <li>Provide the services and features available on GameWagrs.com, including facilitating challenges between users and managing seamless transactions</li>
                    <li>Improve and develop our products and services</li>
                    <li>Contact users regarding site updates and promotions</li>
                    <li>Ensure the integrity of the site and protect against fraud</li>
                  </ul>
                </div>               
                <div className="right-faq-head">Data Sharing:</div>
                <div className="right-faq-body">
                  We will not sell or transfer user data to any third-party companies or organizations.
                </div>
                <div className="right-faq-head">Data Security:</div>
                <div className="right-faq-body">
                  We take reasonable measures to ensure the security of user data, including implementing technical and organizational measures to protect against unauthorized access, alteration, disclosure, or destruction.
                </div>
                <div className="right-faq-head">User Control:</div>
                <div className="right-faq-body">
                  Users have the right to control their data and can request access, correction, or deletion of their data by contacting us at <a href="mailto:administrator@gamewagrs.com">administrator@GameWagrs.com.</a>
                </div>
                <div className="right-faq-head">Updates:</div>
                <div className="right-faq-body">
                  We may update this policy from time to time, and we will notify users of any material changes to the policy. Your continued use of GameWagrs.com after any changes to the policy indicates your acceptance of the changes.
                </div>
                <div className="right-faq-head">Contact Us:</div>
                <div className="right-faq-body">
                  If you have any questions or concerns about this policy or our use of your data, please contact us at <a href="mailto:administrator@gamewagrs.com">administrator@GameWagrs.com.</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserDataPolicy;
