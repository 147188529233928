import axios from 'axios'
import { Loader } from "../component/Loader";
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import "./CashoutTransactions.css"
import { errorToast } from '../constants/ToastNotifications';
import moment from 'moment';
import CashoutSkeleton from '../skeletons/CashoutSkeleton';
import { ToastContainer } from "react-toastify";

export const CashoutTransactions = () => {
const [alltransactions,setAlltransactions] = useState([])
const [loading, setLoading] = useState(false);

const data = JSON.parse(localStorage.getItem("nftuser"))
const navigate = useNavigate()

const getallchallenge = async()=>{
 try{
  setLoading(true);
  const res = await axios.post("/api/auth/getpaypalcashout",{id:data._id})
  setLoading(false);
  setAlltransactions(res.data)
 } catch(error){
  errorToast("Something went wrong!")
 }
}
console.log("alltransactions",alltransactions)
useEffect(()=>{
getallchallenge()
},[])

const useStyles = makeStyles({
  ts: {
    color: "#ffffff",
    fontSize: `${window.innerWidth > 400 ? "14px" : "13px"}`,
    fontFamily: "Roboto,sans-serif",
    "&:hover": {
      color: "#ffffff",
    },
    "&:active": {
      color: "#ffffff",
    },
    "&:focus": {
      color: "#ffffff",
    },
  },
  tlink:{
    color: "#00c853",
  fontSize: `${window.innerWidth > 400 ? "14px" : "12px"}`,
  fontFamily: "Audiowide",
  "&:hover": {
    color: "#00c853",
  },
  "&:active": {
    color: "#00c853",
  },
  "&:focus": {
    color: "#00c853",
  },
  },
  selectDropdown: { color: "#fff", backgroundColor: "#0b0b27" },
  menuItem: {
    "&:hover": {
      backgroundColor: "#06001b",
    },
    select: {
      color: "red",
      background: "white",
    },
  },
});


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#0b0b27",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#06001b",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableHeader = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#06001b",
  },
}));
const classes = useStyles();
  return (
    <>
    <ToastContainer />
    <div className="trans-table">
    <div className="trans-logs container-fluid ">
      <div className="challenge-heading trans-head">
        <h1>Paypal Cashout Logs</h1>
      </div>
 {loading ? (
            <div className="loader-hight">
              <Loader />
            </div>
          ) : (
            <>{alltransactions?.length > 0 ?<TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                  <TableHead>
                    <StyledTableHeader>
                      <TableCell className={classes.ts} align="center">#</TableCell>
                      <TableCell className={classes.ts} align="center">Payout ID</TableCell>
                      <TableCell className={classes.ts} align="center">Transaction Created</TableCell>
                      <TableCell className={classes.ts} align="center">Amount</TableCell>
                      <TableCell className={classes.ts} align="center">Amount Currency</TableCell>
                      <TableCell className={classes.ts} align="center">Paypal Fees</TableCell>
                      <TableCell className={classes.ts} align="center">Fees Currency</TableCell>
                      {/* <TableCell className={classes.ts} align="center">Accept Challenge</TableCell> */}
                      {/* <TableCell className={classes.ts} align="center">Cards</TableCell> */}
                      
                    </StyledTableHeader>
                  </TableHead>
                  <TableBody>
                    {alltransactions
                      .map((items, index) => (
                        <StyledTableRow
                          key={items.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell className={classes.ts} align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                            {items.payout_batch_id}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                            {moment(items.time_created).format("DD/MM/YYYY h:mm:ss a")}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items.amount_value}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items.amount_currency}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items.fees_value}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items.fees_currency}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer> : <div className="no-records-found">No records found.</div>}</>
               )}
    </div>
 </div>
 </>
  )
}
