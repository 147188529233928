import React from 'react'
import { styled, TableCell, TableRow } from '@material-ui/core'
import ContentLoader from 'react-content-loader'

const DuelStatusSkeleton = () => {

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
          backgroundColor: "#0b0b27",
        },
        "&:nth-of-type(even)": {
          backgroundColor: "#06001b",
        },
        // hide last border
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }));

  return [...Array(3)].map((e, i) => (
    <StyledTableRow key={i}>
        <TableCell>
        <ContentLoader
                speed={1}
                width={180}
                height={50}
                viewBox="0 0 200 70"
                backgroundColor={'#333'}
                foregroundColor={'#999'}
                >
                <rect x="80" y="20" rx="3" ry="3" width="200" height="25" /> 
            </ContentLoader>
        </TableCell>
        <TableCell>
        <ContentLoader
                speed={1}
                width={180}
                height={50}
                viewBox="0 0 200 70"
                backgroundColor={'#333'}
                foregroundColor={'#999'}
                >
                <rect x="80" y="20" rx="3" ry="3" width="200" height="25" /> 
            </ContentLoader>
        </TableCell>
        <TableCell>
        <ContentLoader
                speed={1}
                width={180}
                height={50}
                viewBox="0 0 200 70"
                backgroundColor={'#333'}
                foregroundColor={'#999'}
                >
                <circle cx="30" cy="30" r="30" />
                <rect x="80" y="20" rx="3" ry="3" width="150" height="25" />  
            </ContentLoader>
        </TableCell>
        <TableCell>
        <ContentLoader
                speed={1}
                width={180}
                height={50}
                viewBox="0 0 200 70"
                backgroundColor={'#333'}
                foregroundColor={'#999'}
                >
                <circle cx="30" cy="30" r="30" />
                <rect x="80" y="20" rx="3" ry="3" width="150" height="25" /> 
            </ContentLoader>
        </TableCell>
        <TableCell>
        <ContentLoader
                speed={1}
                width={180}
                height={50}
                viewBox="0 0 200 70"
                backgroundColor={'#333'}
                foregroundColor={'#999'}
                >
                <rect x="80" y="20" rx="3" ry="3" width="200" height="25" /> 
            </ContentLoader>
        </TableCell>
        <TableCell>
        <ContentLoader
                speed={1}
                width={180}
                height={50}
                viewBox="0 0 200 70"
                backgroundColor={'#333'}
                foregroundColor={'#999'}
                >
                <rect x="80" y="20" rx="3" ry="3" width="200" height="25" /> 
            </ContentLoader>
        </TableCell>
        <TableCell>
        <ContentLoader
                speed={1}
                width={180}
                height={50}
                viewBox="0 0 200 70"
                backgroundColor={'#333'}
                foregroundColor={'#999'}
                >
                <rect x="80" y="20" rx="3" ry="3" width="200" height="25" /> 
            </ContentLoader>
        </TableCell>
    </StyledTableRow>
  ))
}

export default DuelStatusSkeleton