export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"

export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS"
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL"




export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL"
export const DETAIL_USER_REQUEST = "DETAIL_USER_REQUEST";
export const DETAIL_USER_SUCCESS = "DETAIL_USER_SUCCESS"
export const DETAIL_USER_FAIL = "DETAIL_USER_FAIL"
export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL"
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET"
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL"

export const UPDATE_PROFIE_REQUEST = "UPDATE_PROFIE_REQUEST"
export const UPDATE_PROFIE_SUCCESS = "UPDATE_PROFIE_SUCCESS"
export const UPDATE_PROFIE_FAIL = "UPDATE_PROFIE_FAIL"
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET"

export const API_SUCCESS  = "API_SUCCESS"
export const API_REQUEST  = "API_REQUEST"
export const API_FAIL  = "API_FAIL"


export const USER_SUCCESS  = "API_SUCCESS"
export const USER_REQUEST  = "API_REQUEST"
export const USER_FAIL  =   "API_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS"
