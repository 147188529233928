import React from 'react'
import "./Loader.css";

const NotificationSkeleton = () => {
  return (
    <div className="loading">
    <div></div>
  </div>
  )
}

export default NotificationSkeleton