import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import "./Coupon.css";
import { Admin } from "./Admin";
import { successToast,errorToast } from "../constants/ToastNotifications";
import { ToastContainer } from "react-toastify";

export const Coupon = () => {
    const [todos, setTodos] = useState([]);
    const [newTodo, setNewTodo] = useState('');
    const [editingTodoId, setEditingTodoId] = useState(null);
    const [editedTodo, setEditedTodo] = useState('');
  
    const addTodo = (newTodo) => {
      const todoItem = {
        id: new Date().getTime(),
        todo: newTodo,
      };
      setTodos([...todos, todoItem]);
    };
  
    const handleEdit = (id) => {
      const todoItem = todos.find((todo) => todo.id === id);
      setEditedTodo(todoItem.todo);
      setEditingTodoId(id);
    };
  
    const handleUpdate = () => {
      const updatedTodos = todos.map((todo) =>
        todo.id === editingTodoId ? { ...todo, todo: editedTodo } : todo
      );
      setTodos(updatedTodos);
      setEditingTodoId(null);
      setEditedTodo('');
    };
  
    const handleDelete = (id) => {
      const updatedTodos = todos.filter((todo) => todo.id !== id);
      setTodos(updatedTodos);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (newTodo.trim() === '') {
        return; // Don't add empty todos
      }
      addTodo(newTodo);
      setNewTodo('');
    };
  
    useEffect(() => {
      const storedTodos = localStorage.getItem('todos');
      if (storedTodos) {
        setTodos(JSON.parse(storedTodos));
      }
    }, []);
  
    // Save to local storage whenever they change
    useEffect(() => {
      localStorage.setItem('todos', JSON.stringify(todos));
    }, [todos]);
  
    return (
        <div className="user_content">
                <div className="user_slide">
                  <Admin />
                </div>
                <div className="container-fluid  table_A">
                  <div className="challenge-heading">
                 <h1>Coupons Available</h1>
                 <div>
                <form onSubmit={handleSubmit} className="todo-ul-li">
                  <div className="add-coupon">
                  <input
                  id='coupons'
                  type="text"
                  value={newTodo}
                  onChange={(e) => setNewTodo(e.target.value)}
                  autoComplete='coupons'
                  />
                  <button type="submit">Add Coupon</button>
                  </div>
                </form>
                  <ul className="form-ul"> 
                  {todos.map((todo) => (
                    <li key={todo.id}>
                        {editingTodoId === todo.id ? (
                    <div className="edit-coupon">
                    <input
                      type="text"
                      value={editedTodo}
                      onChange={(e) => setEditedTodo(e.target.value)}
                      autoFocus
                    />
                    <button onClick={handleUpdate}>UPDATE</button>
                  
                    </div>
                    ) : (
                    <>
                    <div className="coupon-list">
                      <div>
                    <span className="coupon-item">{todo.todo}</span>
                    </div>
                    <div>
                    <button onClick={() => handleEdit(todo.id)}><i class="fas fa-edit"></i></button>
                    <button onClick={() => handleDelete(todo.id)} style={{marginLeft: "10px"}}><i class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                    </div>
                    </>
                    )}
                </li>
                ))}
                </ul>
      </div>
      </div>
      </div>
      </div>
    );
  };
  
  export default Coupon;
  