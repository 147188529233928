import React,{ useEffect, useState } from 'react'
import axios from "axios";
import { toast } from 'react-toastify';
import './OverWolf.css'
import { Loader } from './Loader';

const OverWolf = () => {
  const [isLoading, setIsLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  const getUserDetail = async () => {
    setIsLoading(true)
    const userdata = JSON.parse(localStorage.getItem("nftuser"));
    if(!userdata){
      toast.error('You need to login first or try again')
    }
    try {
      const { data } = await axios.post(`/api/auth/overwolf?code=${code}`, {
        user : userdata?.user?._id
      });
      if(data?.success){
        localStorage.setItem("overwolfuser",JSON.stringify({...data.data,expire:new Date().getDate()+7}))
        const existingNftuserData = JSON.parse(localStorage.getItem("nftuser"));
        existingNftuserData.overwolf = {
          ...data.data,
          expire: new Date().getDate() + 7,
        };
        localStorage.setItem("nftuser", JSON.stringify(existingNftuserData));
      }
      
    } catch (error) {
      toast.error(new Error(error?.response?.data?.error) || new Error(error?.response?.data?.error?.error_description) || new Error(error?.message))
    } 
    setIsLoading(false)
    window.close();
  };

  useEffect(() => {
    getUserDetail()
  }, [code])

  return (
    <div className='overwolf-main'>
      {isLoading && (
        <Loader />
      )}
      <h2>Please wait... The process may take a moment.</h2>
    </div>
  )
}

export default OverWolf