import React from 'react'
import { styled, TableCell, TableRow } from '@material-ui/core'
import ContentLoader from 'react-content-loader'

const MarketplaceSkeleton = () => {

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
          backgroundColor: "#0b0b27",
        },
        "&:nth-of-type(even)": {
          backgroundColor: "#06001b",
        },
        // hide last border
        "&:last-child td, &:last-child th": {
          border: 0,
        },
      }));

  return [...Array(3)].map((e, i) => (
        <StyledTableRow key={i}>
                <TableCell>
                    <ContentLoader
                      speed={1}
                      width={50}
                      height={50}
                      viewBox="0 0 60 70"
                      backgroundColor={'#333'}
                      foregroundColor={'#999'}
                      >
                        <circle cx="30" cy="30" r="30" />
                    </ContentLoader>
                 </TableCell>
                <TableCell>
                    <ContentLoader
                      speed={1}
                      width={150}
                      height={50}
                      viewBox="0 0 200 70"
                      backgroundColor={'#333'}
                      foregroundColor={'#999'}
                      >
                        <rect x="30" y="20" rx="3" ry="3" width="200" height="25" /> 
                    </ContentLoader>
                </TableCell>
                <TableCell>
                  <ContentLoader
                      speed={1}
                      width={150}
                      height={50}
                      viewBox="0 0 200 70"
                      backgroundColor={'#333'}
                      foregroundColor={'#999'}
                      >
                        <rect x="30" y="20" rx="3" ry="3" width="200" height="25" /> 
                    </ContentLoader>
                </TableCell>
                <TableCell>
                <ContentLoader
                      speed={1}
                      width={150}
                      height={50}
                      viewBox="0 0 200 70"
                      backgroundColor={'#333'}
                      foregroundColor={'#999'}
                      >
                        <rect x="30" y="20" rx="3" ry="3" width="200" height="25" /> 
                    </ContentLoader>
                </TableCell>
                <TableCell>
                <ContentLoader
                      speed={1}
                      width={150}
                      height={50}
                      viewBox="0 0 200 70"
                      backgroundColor={'#333'}
                      foregroundColor={'#999'}
                      >
                        <rect x="30" y="20" rx="3" ry="3" width="200" height="25" /> 
                    </ContentLoader>
                </TableCell>
                <TableCell>
                <ContentLoader
                      speed={1}
                      width={100}
                      height={50}
                      viewBox="0 0 200 70"
                      backgroundColor={'#333'}
                      foregroundColor={'#999'}
                      >
                        <rect x="30" y="20" rx="3" ry="3" width="200" height="25" /> 
                    </ContentLoader>
                </TableCell>
                <TableCell>
                <ContentLoader
                      speed={1}
                      width={100}
                      height={50}
                      viewBox="0 0 200 70"
                      backgroundColor={'#333'}
                      foregroundColor={'#999'}
                      >
                        <rect x="30" y="20" rx="3" ry="3" width="200" height="25" /> 
                    </ContentLoader>
                </TableCell>
                <TableCell>
                <ContentLoader
                      speed={1}
                      width={100}
                      height={50}
                      viewBox="0 0 200 70"
                      backgroundColor={'#333'}
                      foregroundColor={'#999'}
                      >
                        <rect x="30" y="20" rx="3" ry="3" width="200" height="25" /> 
                    </ContentLoader>
                </TableCell>
                <TableCell>
                <ContentLoader
                      speed={1}
                      width={150}
                      height={50}
                      viewBox="0 0 200 70"
                      backgroundColor={'#333'}
                      foregroundColor={'#999'}
                      >
                        <rect x="30" y="20" rx="3" ry="3" width="200" height="25" /> 
                    </ContentLoader>
                </TableCell>
                {/* <TableCell>
                    <ContentLoader
                      speed={1}
                      width={150}
                      height={150}
                      viewBox="0 0 200 150"
                      backgroundColor={'#333'}
                      foregroundColor={'#999'}
                      >
                     <rect x="70" y="20" width="100" height="200"/>  
                    </ContentLoader>
                </TableCell> */}
        </StyledTableRow>
  ))
}

export default MarketplaceSkeleton;