import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import "./Settings.css";
import { Admin } from "./Admin";
import { successToast,errorToast } from "../constants/ToastNotifications";
import { ToastContainer } from "react-toastify";
export const Settings = () => {
    const [isEditingFlat, setIsEditingFlat] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [FlatServiceTax, setFlatServiceTax] = useState("");
    const [ServiceTax, setServiceTax] = useState("");
    const inputRefFlat = useRef(null);
    const inputRef = useRef(null);

    const handleEditFlat = () => {
        setIsEditingFlat(true);
        inputRefFlat.current.focus();
      };

    const handleEdit = () => {
        setIsEditing(true);
        inputRef.current.focus();
      };
    
  const handleSubmitFlat = async (e) => {
    e.preventDefault();
    if (!FlatServiceTax) {
        errorToast("Please enter some value!")
        return;
      }
    setIsEditingFlat(false);
    const res = await axios.post("/api/auth/flatservicetax",{
      flatservicetax: FlatServiceTax,
    })
    console.log(res.data)
    if(res){
      successToast(`Service charge updated to $${res.data.flatservicetax} successfully`)
    } else {
      errorToast("Something went wrong please try again")
    }
  };
  const getFlatserviceCharge = async () =>{
    const res = await axios.get("/api/auth/getflatservicetax")
    if(res){
      console.log("data",res)
      setFlatServiceTax(res?.data?.flatservicetax) 
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!ServiceTax) {
        errorToast("Please enter some value!")
        return;
      }
    setIsEditing(false);
    const res = await axios.post("/api/auth/servicetax",{
      servicetax: ServiceTax,
    })
    console.log(res.data)
    if(res){
      successToast(`Service charge updated to $${res.data.servicetax} successfully`)
    } else {
      errorToast("Something went wrong please try again")
    }
  };
  const getserviceCharge = async () =>{
    const res = await axios.get("/api/auth/getservicetax")
    if(res){
      console.log("data",res)
      setServiceTax(res?.data?.servicetax) 
    }
  }

  useEffect(() => {
    if (isEditingFlat) {
      inputRefFlat.current.focus();
    }
  }, [isEditingFlat]);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

useEffect(()=>{
  getFlatserviceCharge();
  getserviceCharge()
},[])

  return (
   <>
   <ToastContainer />
    <div className="user_content">
        <div className="user_slide">
          <Admin />
        </div>
        <div className="container-fluid  table_A">
          <div className="challenge-heading">
            <h1>Settings</h1>
          </div>
          <div className="service-charge-text">
          <form className="admin-service" onSubmit={handleSubmitFlat}>
            <div className="input-row">
              <div className="input-container">
                <label htmlFor='servicetax'>Service charge FlatRate:</label>
                <input
                  id="servicetax"
                  type="number"
                  aria-label="Search"
                  value={FlatServiceTax}
                  onChange={(e) => setFlatServiceTax(e.target.value)}
                  disabled={!isEditingFlat}
                  ref={inputRefFlat}
                />
              </div>
              <div className="button-container">
                <button type="button" onClick={handleEditFlat}>EDIT</button>
                <button type="submit" style={{marginLeft: "10px"}}>ADD</button>
              </div>
            </div>
          </form>
        </div>

          <div className="service-charge-text">
          <form className="admin-service" onSubmit={handleSubmit}>
            <div className="input-row">
              <div className="input-container">
                <label htmlFor='servicecharge'>Service charge Percentage:</label>
                <input
                  id="servicecharge"
                  type="number"
                  aria-label="Search"
                  value={ServiceTax}
                  onChange={(e) => setServiceTax(e.target.value)}
                  disabled={!isEditing}
                  ref={inputRef}
                />
              </div>
              <div className="button-container">
                <button type="button" onClick={handleEdit}>EDIT</button>
                <button type="submit" style={{marginLeft: "10px"}}>ADD</button>
              </div>
            </div>
          </form>
        </div>

          </div>
          </div>
   </>
  )
}
