import React, { useEffect, useState } from "react";
import './TopRecWinner.css'
import AvatarVS from '../images/avatarVS.svg';
import axios from "axios";

const TopRecWinner = () => {
    const [challengedata, setChallengeData] = useState([]);
    const [userfunddata,setUserFundData] = useState([]);
      useEffect(() => {
        getlatestfivechallData();
        getlatestfundUsers();
      }, []);
      const getlatestfivechallData = async () => {
        const latestchaldata = await axios.post("/api/auth/getlatestfivechallenges");
        setChallengeData(latestchaldata?.data);
      }
      const getlatestfundUsers = async () => {
        const latestusersfunddata = await axios.post("/api/auth/getlatestmorefundusers");
        setUserFundData(latestusersfunddata?.data);
      }
  return (
    <>
    <section className="top-earner">
            <div className='container mt-10'>
            <h2 className='section-title new-title d-flex align-items-center justify-content-center'>
            Recent Winners <span>Turn every win into profit!</span>
            </h2>
            <div className='recent-winner d-flex align-items-center justify-content-center'>
                <div className='recent-winner-inner-wrapper d-flex flex-wrap align-items-center justify-content-center'>
                {challengedata.map((item, index) => (
                <div className='recent-block'>
                       <div className=' recent-winner-top d-flex flex-wrap align-items-center justify-content-between'>
                            <div className='recent-user'><img loading="lazy" src={`${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${item?.winner?.avatar}`} alt='padlock' /></div>
                            <div className='recent-user-vs'><img src={AvatarVS} loading="lazy" alt='padlock'/></div>
                            <div className='recent-user'><img loading="lazy" src={`${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${item?.loser?.avatar}`} alt='padlock' /></div>
                       </div>
                       <div className='divider-vs'></div>
                       <div className='recent-winner-bottom d-flex flex-wrap align-items-center'>
                        <h3>{item?.winner?.username}</h3>
                        <span>won</span>
                        <div>${item?.playerwager}!</div>
                       </div>
                    </div>
                ))}
                </div>
            </div>
            </div>
        </section>

        <section className='top-earner'>
            <div className='container'>
            <h2 className='section-title new-title d-flex align-items-center justify-content-center'>
            Top Earners<span>Instant payouts anytime!</span>
            </h2>
            <div className='recent-winner d-flex align-items-center justify-content-center'>
                <div className='recent-winner-inner-wrapper new-wrapper d-flex flex-wrap align-items-center justify-content-center'>
                {userfunddata.map((item, index) => (   
                    <div className='recent-block'>
                       <div className=' recent-winner-top d-flex flex-wrap align-items-center'>
                            <div className='recent-user'><img loading="lazy" src={`${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${item?.user?.avatar}`} alt='padlock'/></div>
                            <h3>{item?.user?.username}</h3>
                       </div>
                       <div className='add-white-space'></div>
                       <div className='recent-winner-bottom'>
                        <div className='mx-auto'>${item.winneramount}</div>
                       </div>
                    </div>
                     ))}
                </div>
            </div>
            </div>
        </section>      
    </>
  )
}

export default TopRecWinner
