import React from "react";
import { Link } from "react-router-dom";
import "../Thankyou.css";

export const Decisioninfo = () => {
  return (
    <>
      <div className="decision-info-section">
        <div className="container">
          <div className="decision-info-text">
            <h1>Your decision was recieved!</h1>
            <h2>The challenge is pending</h2>
          </div>
        </div>
      </div>
    </>
  );
};
