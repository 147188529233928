import React, { useEffect, useState } from "react";
import './Rules.css'
import crown from '../images/crown.png';
import moneybag from '../images/money-bag.png';
import padlock from '../images/padlock.png';

const Rules = () => {
  return (
    <>
        <section className='rules-space'> 
            <div className="container">
                <div className="row gx-45">
                    <div className="col-lg-4 col-md-4">
                        <div className="card-wrapper1 mar-bot">
                            <img loading="lazy"src={crown} alt='crown'/>
                            <p>Collect Profits By Defeating Anyone In A Challenge Or Reach Your Goals In A Solo Showdown!</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="card-wrapper1 mar-bot">
                            <img loading="lazy" src={moneybag} alt='moneybag'/>
                            <p>Solo Showdown: Like A Sports Betting Parlay, But Based On Your Own In-Game Performance And Achievements</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="card-wrapper1 mar-bot">
                            <img loading="lazy" src={padlock} alt='padlock'/>
                            <p>Every Payout Is Secured And Guaranteed To Be Delivered To The Winner. All Backed By PayPal!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>        
    </>
  )
}

export default Rules
