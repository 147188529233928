import React, { useState } from "react";
import { Container, Row, Spinner, Toast } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import logo from "../images/new-logo-2.png"
import "./ResetPassword.css"
import axios from "axios";
import { errorToast, successToast } from "../constants/ToastNotifications";

const ResetPassword = () => {

    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState("");

    const {resetToken} = useParams();
    console.log(resetToken);
    const submitHandler = (e) =>{
        const resetpassword = e.target.value;
        setPassword(resetpassword);
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        setLoader(true);

        try{
        const res = await axios.post("/api/auth/resetPassword",{
            resetToken : resetToken,
            password: password,
        });
        console.log(res, "reset password");
        if (res) {
            // Successful response
            if(res?.data?.message){
                errorToast(res?.data?.message);
                setLoader(false);
                return;
              }
              if(res?.data){
              successToast(res?.data?.data);
              navigate("/login");
              }
          } else {
            console.log(res.status);
          }
        } catch(error){
        console.log(error);
        errorToast("Something went wrong. Please try again.");
        } finally{
            setLoader(false);
        }
        setPassword("");
    }

    return (
        <div className="body-main">
            <div className="login-sec">
                <Container>
                    <div className="reset_container">
                        <div className="reset_form_container">
                            <div className="reset-left">
                                <div className="reset_left_inner">
                                    <img src={logo} alt="Logo"/>
                                </div>
                            </div>
                            <div className="reset_right">
                                <div className="mb-2">
                                <i class="fa fa-lock fa-5x" aria-hidden="true"></i>
                                </div>
                                <form
                                    className="form_container"
                                    onSubmit={handleSubmit}
                                >
                                    <h2 className="text-center">RESET PASSWORD</h2>
                                    <label className="reset-label" style={{textAlign: "left"}}>Enter new password</label>
                                    <input
                                        type="password"
                                        placeholder="New Password"
                                        id="password"
                                        name="password"
                                        required
                                        className="input"
                                        value={password}
                                        onChange={submitHandler}
                                        />
                                    <button type="submit" className="reset_btn" disabled={loader}>
                                    {loader ? <Spinner animation="border" size="sm"/>: "RESET PASSWORD"}
                                    </button>
                                </form>
                                <div className="reset_login">
                                    <Link to="/login">
                                        <button type="link" className="login_link">
                                        Back to Login
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default ResetPassword;