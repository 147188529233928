import React from "react";
import "./NotFound.css"

const NotFound = () =>{

 return <div className='not-found-container'>
     <div className='section-title'>
         <h2>PAGE NOT FOUND</h2>
     </div>
</div>

}
export default NotFound;