import React,{useState,useRef,useEffect} from "react";
import {  useParams } from "react-router-dom";
import "./Purchase.css"
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import { findSource } from "../constants/config";

const Purchase = () =>{
  // const [index,setIndex] = useState(img1)
  const [cardData,setCardData] = useState({})
  const { id } = useParams();

  useEffect(()=>{
    setCardData(findSource(id))
  },[id])

 return<div className='user-profile'>
   <div className='user-profile-container'>
   <div className="profile-head" style={{marginTop:"100px",minHeight:"unset"}}>
     <div className='section-title'>
         <h2>{"Purchase"}</h2>
     </div>
     </div>
     <div className="purchase-body">
        <div className="purchase-body-1">
            <div className="purchase-body-1-1">
              <Carousel interval={50000}>  
               {(cardData.sources || []).map((item,index)=>{
                if(item.type === "video"){
                  return <Carousel.Item key={index}>             
                  <video
                  className="purchase-video"
                    playsInline
                    loop
                    muted
                    alt="All the devices"
                    src={item.link}
                   autoPlay
                  /> 
              </Carousel.Item>
                }else{
                  return <Carousel.Item key={index}>
                      
                  <img src={item.link} className="purchase-image"></img>
                </Carousel.Item>
                   
                }
              }) 
               }
              </Carousel>
            </div>
        </div>
        <div className="purchase-body-2">
        <div className="purchase-1-userdata">
                        <div className="purchase-1-name">
                        {cardData?.name === "Generic" ? "Generic Orignal" : cardData?.name}
                        </div>
                        <div className="purchase-1-subdata">
                           {`2022 Pamini Immaculate Blockchain ${cardData?.name === "Generic" ? "Generic Orignal" : cardData?.name} ${cardData?.additional || "-"} DuelCards level - card.`} 
                        </div>
                        <div  className="purchase-1-bg">
                            <div className="level-name">
                              {cardData?.level || "-"}
                            </div>
                            <div className="level-data">
                              {cardData?.additional || "-"}
                            </div>
                        </div>
                    </div>
        <div className="purchase-2-userdata">
            <div className="price">
              <div className="price-label">
                Price
              </div>
              <div className="price-value">
              {cardData?.price || "-"}
              </div>
            </div>
            <div className="d-flex-center">
              
            <Button variant="primary" className="challenge-btn" style={{cursor:"not-allowed"}}>
              Coming soon
            </Button>
            </div>

        </div>
        </div>  
     </div>
    </div>
</div>

}
export default Purchase