import axios from "axios";
import { Loader } from "../component/Loader";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Admin } from "./Admin";
import { Searchbar } from "../component/Searchbar";
import { Button } from "react-bootstrap";
import EditChallengeModal from "./EditChallengeModal";
import { ToastContainer } from "react-toastify";

import "./TransactionLog.css";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { successToast } from "../constants/ToastNotifications";
import moment from "moment-timezone";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const TransactionLog = () => {
  const navigate = useNavigate();
  const [usercount, setUserCount] = useState();
  const [userdata, setuserdata] = useState([]);
  const [allchallenge, setAllchallenge] = useState([]);
  const [tokenid, setTokenid] = useState(localStorage.getItem("nftadmintoken"));
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("All");
  const [show, setShow] = useState(false);
  const [editChallengeData, setEditChallengeData] = useState({});
  const [isManual, setManual] = useState(false);

  const getallusers = async () => {
    const res = await axios.post("/api/auth/getuserdata");
    setuserdata(res.data);
  }
  const handleModalOpen = (challenge, manual = false) => {
    setManual(manual);
    setEditChallengeData(challenge);
    setShow(true);
  };
  const handleModalClose = () => {
    setManual(false);
    setEditChallengeData({});
    setShow(false);
  };
  // Table sorting and pagination
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // MUI styles override

  const useStyles = makeStyles({
    ts: {
      color: "#ffffff",
      fontSize: `${window.innerWidth > 400 ? "15px" : "13px"}`,
      fontFamily: "Roboto,sans-serif",
      "&:hover": {
        color: "#ffffff",
      },
      "&:active": {
        color: "#ffffff",
      },
      "&:focus": {
        color: "#ffffff",
      },
    },
    selectDropdown: { color: "#fff", backgroundColor: "#0b0b27" },
    menuItem: {
      "&:hover": {
        backgroundColor: "#06001b",
      },
      select: {
        borderRadius: "40px",
        width: "100px",
        height: "27px",
        paddingLeft: "14px",
        paddingTop: "12px",
      },
    },
  });

  const CustomTooltip = withStyles({
    tooltip: {
      color: "white",
      fontSize: "13px",
    },
  })(Tooltip);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#0b0b27",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#06001b",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableHeader = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#06001b",
    },
  }));
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#282454",
      border: "1px solid #ced4da",
      color: "white",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));
  const classes = useStyles();

  const getallchallenges = async () => {
    setLoading(true);
    const res = await axios.post(
      `/api/auth/getalltransactionlog`
    );
    setAllchallenge(res.data);
    setLoading(false);
  };

  const getuserchallangedata = async () => {
    setLoading(true);
    const res = await axios.post(`/api/auth/gettransactionloguser`,{
      userid: filter,
    });
    setAllchallenge(res.data);
    setLoading(false);
  };

  const getadminchallangedata = async () => {
    setLoading(true);
    if(tokenid){
      const adminres = await axios.post(`/api/auth/getadmin`);
      if(adminres){
        const res = await axios.post(`/api/auth/gettransactionloguser`,{
          userid: adminres?.data,
        });
        setAllchallenge(res.data);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
    
  };

  useEffect(() => {
    if (tokenid == null) {
      navigate("/");
    }
    getallchallenges();
  },[]);

  useEffect(() => {
    if (tokenid == null) {
      navigate("/");
    }
    if(filter == "All" ){
      getallchallenges();
    } else if (filter == "ADMIN"){
      getadminchallangedata();
    } else {
      getuserchallangedata();
    }
  }, [filter]);

  useEffect(() => {
    getallusers();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="challenge_content">
        <div className="user_slide">
          <Admin />
        </div>
        <div className="challengetable container-fluid ">
          <div className="challenge-heading ">
            <h1>Transaction Logs</h1>
          </div>
          {loading ? (
            <div className="loader-hight">
              <Loader />
            </div>
          ) : (
            <div className="scrolltable">
              <div className="search-bar-container-admin">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label test">
                    Filter By User
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={filter}
                    label="Age"
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                    className="mui-select"
                    style={{ width: "250px", fontSize: "15px" }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="ADMIN">ADMIN</MenuItem>
                    {userdata.map((items, index) => (
                      
                      <MenuItem value={items._id}>{items.username}</MenuItem>
                     
                    ))}
                    {/* // <MenuItem value="All">All</MenuItem>
                    // <MenuItem value={"pending"}>Pending</MenuItem>
                    // <MenuItem value={"accepted"}>Accepted</MenuItem>
                    // <MenuItem value={"decline"}>Declined</MenuItem>
                    // <MenuItem value={"declared"}>Result declared</MenuItem>
                    // <MenuItem value={"manual"}>Manual review</MenuItem> */}
                  </Select>
                </FormControl>
                {/* <div style={{ marginTop: "14px" }}>
                  <Searchbar
                    search={setSearch}
                    submit={getallchallenges}
                    value={search}
                  />
                </div> */}
              </div>
              <>{allchallenge.length>0 ? <div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead order={order} orderBy={orderBy}>
                    <StyledTableHeader>
                      <TableCell className={classes.ts} align="center">Username</TableCell>
                      <TableCell className={classes.ts} align="center">Order Id</TableCell>
                      <TableCell className={classes.ts} align="center">Previous Balance</TableCell>
                      <TableCell className={classes.ts} align="center">Transaction Amount</TableCell>
                      <TableCell className={classes.ts} align="center">Service Charge</TableCell>
                      <TableCell className={classes.ts} align="center">Current Balance</TableCell>
                      <TableCell className={classes.ts} align="center">In App Funds</TableCell>
                      <TableCell className={classes.ts} align="center">Transaction Type</TableCell>
                      <TableCell className={classes.ts} align="center">Transaction Method</TableCell>
                      <TableCell className={classes.ts} align="center">Challenge Status</TableCell>
                      <TableCell className={classes.ts} align="center">Transactions Time</TableCell>
                    </StyledTableHeader>
                  </TableHead>
                  <TableBody>
                    {allchallenge
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((items, index) => (
                        <StyledTableRow
                          key={items?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          onDoubleClick={() => handleModalOpen(items)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell className={classes.ts} align="center">
                            {items?.username}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                            {items?.orderid}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                            {parseFloat(items?.balance_before_transaction) % 1 === 0 ? parseFloat(items?.balance_before_transaction).toString() : parseFloat(items?.balance_before_transaction).toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items?.transaction_amount ? parseFloat(items?.transaction_amount) % 1 === 0 ? parseFloat(items?.transaction_amount).toString() : parseFloat(items?.transaction_amount).toFixed(2) : null || "-"}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items?.servicecharge ? parseFloat(items?.servicecharge) % 1 === 0 ? parseFloat(items?.servicecharge).toString() : parseFloat(items?.servicecharge).toFixed(2) : null || "-"}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {parseFloat(items?.balance_after_transaction) % 1 === 0 ? parseFloat(items?.balance_after_transaction).toString() : parseFloat(items?.balance_after_transaction).toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items?.in_app_used_fund ?parseFloat(items?.in_app_used_fund) % 1 === 0 ? parseFloat(items?.in_app_used_fund).toString() : parseFloat(items?.in_app_used_fund).toFixed(2) : null || "-"}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                            {items?.ordertype}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                            {items?.payment_method || "-"}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                            {items?.moneystatus}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {moment(items?.createdAt).tz(moment.tz.guess()).format("MM/DD/YYYY h:mm A")}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allchallenge?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  MenuProps: { classes: { paper: classes.selectDropdown } },
                }}
                classes={{ menuItem: classes.menuItem }}
                className={classes.ts}
              />
            </div>: <div className="user-no-trans-log-msg">{allchallenge?.message}</div>}</>
            </div>
          )}
        </div>
        <EditChallengeModal
          challenge={editChallengeData}
          getChallenges={getallchallenges}
          show={show}
          handleModalOpen={handleModalOpen}
          handleModalClose={handleModalClose}
          isManual={isManual}
        />
      </div>
    </>
  );
};
