import "./Faq.css";

const TermsOfService = () => {
  return (
    <div className="body-main">
      <div className="faq">
        <div className="faq-container">
          <div className='section-title'>
            <h2>Terms of Service</h2>
          </div>
          <div className="faq-main terms-service">
            <div className="">
              <div className="">
                <div className="right-faq-body">Effective Date: May 27th, 2023</div>
                <div className="right-faq-body">
                Welcome to GameWagrs.com, an online E-sports platform organizing micro-tournaments with prize purses funded by players' entrance fees. As the tournament organizers, GameWagrs charges a small service fee for each micro-tournament. By using GameWagrs.com, you agree to be part of these micro-tournaments and abide by these Terms of Service ("Agreement").
                </div>
                <div className="right-faq-head">1. Acceptance of Terms</div>
                <div className="right-faq-body">
                Upon accessing or utilizing GameWagrs.com, you hereby acknowledge and consent to the terms of this Agreement. Should you disagree with any part of this Agreement, refrain from participating in the micro-tournaments hosted on the GameWagrs.com platform. Please be aware that GameWagrs.com retains the right to make amendments to this Agreement at any time, and such revisions shall be deemed effective immediately upon their posting on the GameWagrs.com platform.
                </div>
                <div className="right-faq-head">2. Eligibility</div>
                <div className="right-faq-body">
                  To use GameWagrs.com, you must be at least 18 years of age or older, or the age of legal majority in your jurisdiction. By using GameWagrs.com, you represent and warrant that you are at least 18 years of age or older or the age of legal majority in your jurisdiction, and that you have the right, authority, and capacity to enter into this Agreement.
                </div>
                <div className="right-faq-head">3. User Conduct</div>
                <div className="right-faq-body">
                  You agree to use GameWagrs.com only for lawful purposes and in compliance with all applicable laws and regulations. You agree not to engage in any activity that would constitute a criminal offense, give rise to a civil liability, or otherwise violate any law, rule, or regulation. You also agree not to use GameWagrs.com to distribute or transmit any harmful or malicious software or code, or to engage in any conduct that could damage, disable, or impair the GameWagrs.com platform.
                </div>
                <div className="right-faq-head">4. Intellectual Property</div>
                <div className="right-faq-body">
                GameWagrs.com is protected by copyright, trademark, and other laws of the United States and foreign countries. You agree not to copy, modify, distribute, or otherwise use any of the content on GameWagrs.com without the prior written permission of GameWagrs.com.
                </div>
                <div className="right-faq-head">5. Disclaimers</div>
                <div className="right-faq-body">
                  By accessing and utilizing the GameWagrs.com platform, users acknowledge and agree to participate in "micro-tournaments," wherein each player contributes an entrance fee to form a prize purse. GameWagrs.com acts solely as a tournament organizer, facilitating these "micro-tournaments" and charging a nominal service fee. Users understand and accept that GameWagrs.com provides the Gamewagrs Validator app as a referee to determine the winner of each "micro-tournament." However, GameWagrs.com provides no warranties, whether express or implied, including but not limited to merchantability and fitness for a particular purpose. The platform is provided "as is," and GameWagrs.com disclaims any guarantee of uninterrupted or error-free services, corrections of defects, or absence of viruses or harmful components, to the fullest extent permissible by law. Users must adhere to these terms and conditions when engaging with the GameWagrs.com platform.
                </div>
                <div className="right-faq-head">6. Limitation of Liability</div>
                <div className="right-faq-body">
                By utilizing the GameWagrs.com platform, users expressly agree to participate in micro-tournaments wherein a prize purse is funded by each player's entrance fee. GameWagrs acts as the tournament organizer and may charge a small service fee for each micro-tournament. The Gamewagrs Validator app shall function as a referee for each micro-tournament to determine the winner. To the fullest extent allowed by applicable law, GameWagrs.com disclaims liability for any direct, indirect, incidental, special, or consequential damages arising from or related to your use of the platform, including, but not limited to, damages for loss of profits, use, data, or other intangible losses, even if GameWagrs.com has been informed of the potential for such damages.
                </div>
                <div className="right-faq-head">7. Indemnification</div>
                <div className="right-faq-body">
                By participating in any "micro-tournament" facilitated by GameWagrs.com, you hereby agree to indemnify, defend, and hold harmless GameWagrs.com, its affiliates, and their respective officers, directors, employees, and agents from and against any and all claims, damages, obligations, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of the GameWagrs.com platform. This includes any disputes or issues related to the prize purse, entrance fees, or the results determined by the Gamewagrs Validator app acting as a referee for the "micro-tournament".
                </div>
                <div className="right-faq-head">8. Governing Law and Jurisdiction</div>
                <div className="right-faq-body mb-2">
                This User Agreement shall be subject to and governed by the laws of the State of Utah, excluding any principles of conflict of laws. Any dispute arising out of or in connection with this User Agreement shall be resolved in accordance with the dispute resolution provisions stipulated in applicable local and state laws. By participating in a "micro-tournament" on Gamewagrs.com, users agree to be bound by this Agreement, acknowledging that Gamewagrs acts solely as the tournament organizer and may charge a service fee for each "micro-tournament." Users further consent to the use of the Gamewagrs Validator app, which serves as a referee for each "micro-tournament" to determine the winner.
                </div>
                <div className="right-faq-body">If you live in one of the following states, you may not play for cash prizes on Gamewagrs: Arizona, Arkansas, Connecticut, Delaware, Florida, Indiana, Louisiana, Maryland, Minnesota, Montana, South Carolina, South Dakota, Tennessee, and Wyoming.</div>  
                <div className="right-faq-head">9. Governing Law and Dispute Resolution</div>
                <div className="right-faq-body mb-2">
                These Terms of Service govern the participation in "Micro-Tournaments" hosted on Gamewagrs.com, wherein users agree to compete in a tournament with a prize purse funded by each player's entrance fee. Gamewagrs acts as the tournament organizer, charging a small service fee for each "Micro-Tournament." By utilizing the Gamewagrs Validator app as a referee for each "Micro-Tournament" to determine the winner, users agree to abide by these terms.
                </div>
                <div className="right-faq-body">
                Disputes arising from these Terms of Service or the use of GameWagrs.com shall be governed by the laws of the state of California, irrespective of conflict of laws provisions. Legal actions or proceedings concerning these Terms of Service or the use of GameWagrs.com shall exclusively take place in the federal or state courts located in San Francisco, California. Each party irreversibly submits to the jurisdiction of such courts in any legal action or proceeding.
                </div>
                <div className="right-faq-head">10. Changes to These Terms of Service</div>
                <div className="right-faq-body">
                We hereby assert the exclusive right to modify or replace these Terms of Service at our sole discretion. In the event of a material revision, a minimum notice period of 30 days shall be granted before the new terms are enacted. The definition of what constitutes a "material change" remains within our sole purview. By persisting in accessing or utilizing our Service subsequent to the implementation of said revisions, you unequivocally consent to abide by the amended terms. Should you disagree with the revised terms, we request that you immediately cease using the Service. As tournament organizers, Gamewagrs.com reserves the right to conduct "micro-tournaments" wherein the prize purse is funded by each player's entrance fee, and we, as organizers, levy a nominal service fee for each such "micro-tournament". Furthermore, we shall provide the Gamewagrs Validator app to serve as an impartial referee for each "micro-tournament", ensuring an equitable determination of the winner.
                </div>
                <div className="right-faq-head">11. Contact Us</div>
                <div className="right-faq-body">
                  If you have any questions about these Terms of Service, please contact us at <a href="mailto:administrator@GameWagrs.com">administrator@GameWagrs.com.</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsOfService;
