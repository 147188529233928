import React, { useEffect, useState } from "react";
import './Partners.css'

const Partners = () => {
  return (
    <>
        <div className="container">
          <div className="partner-container">
            <div>
                <img loading="lazy" src="./Paypal.svg" className="" alt="img"/>
            </div>
            <div>
                <img loading="lazy" src="./AWS.svg" className="" alt="img"/>
            </div>
            <div>
                <img loading="lazy" src="./OW.svg" className="" alt="img"/>
            </div>
            <div>
                <img loading="lazy"src="./Vector.svg" className="" alt="img"/>
            </div>
          </div>
        </div>      
    </>
  )
}

export default Partners
