import React from "react";
import "./Features.css";
// import topimg from "../images/leaderboards.png";
// import bottomimg from "../images/public-challengers.png";
import { Link,useNavigate } from "react-router-dom";


const Features = () =>{
    const navigate = useNavigate();
    const goToSignup = () => {
            navigate("/register");
    }
    return(
        <>
       
        <section className="features-space">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 features-title">
                        <h3>Public Challenges</h3>
                        <p className="features-content">Show the world your skills and compete against anyone in a "micro-<br/>tournament" challenging someone for a chance to profit! Start your <br/> own challenge or accept one offered from another player. This is<br/> 'Win2Earn' gaming.</p>
                        <Link to="/challengemarketplace" className="btn btn-primary primary-btn-linear-bg public-chal-btn-top">
                            Public Challenges
                        </Link>
                    </div>
                    <div className="col-lg-6 features-img">
                    {/* <iframe width="300" height="425" laoading="lazy" src="https://www.youtube.com/embed/Is7NexhOGtk?si=-kCqz18Leb30Wnyh&amp;controls=0&amp;start=11" title="Gamewagrs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                    <lite-youtube videoid="Is7NexhOGtk"></lite-youtube>
                    </div>
                </div>
            </div>
        </section>

        </>
    )
}

export default Features;