import axios from "axios";
import moment from "moment";

import { Loader } from "../component/Loader";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Admin } from "./Admin";
import { Searchbar } from "../component/Searchbar";
import { Button } from "react-bootstrap";
import EditChallengeModal from "./EditChallengeModal";
import { ToastContainer } from "react-toastify";

import "./Table_challenge.css";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { successToast } from "../constants/ToastNotifications";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const Table_challenge = () => {
  const navigate = useNavigate();
  const [usercount, setUserCount] = useState();
  const [userdata, setuserdata] = useState([]);
  const [allchallenge, setAllchallenge] = useState([]);
  const [tokenid, setTokenid] = useState(localStorage.getItem("nftadmintoken"));
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("All");
  const [show, setShow] = useState(false);
  const [editChallengeData, setEditChallengeData] = useState({});
  const [isManual, setManual] = useState(false);

  const handleModalOpen = (challenge, manual = false) => {
    setManual(manual);
    setEditChallengeData(challenge);
    setShow(true);
  };
  const handleModalClose = () => {
    setManual(false);
    setEditChallengeData({});
    setShow(false);
  };
  // Table sorting and pagination

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // MUI styles override

  const useStyles = makeStyles({
    ts: {
      color: "#ffffff",
      fontSize: `${window.innerWidth > 400 ? "15px" : "13px"}`,
      fontFamily: "Roboto,sans-serif",
      "&:hover": {
        color: "#ffffff",
      },
      "&:active": {
        color: "#ffffff",
      },
      "&:focus": {
        color: "#ffffff",
      },
    },
    selectDropdown: { color: "#fff", backgroundColor: "#0b0b27" },
    menuItem: {
      "&:hover": {
        backgroundColor: "#06001b",
      },
      select: {
        borderRadius: "40px",
        width: "100px",
        height: "27px",
        paddingLeft: "14px",
        paddingTop: "12px",
      },
    },
  });

  const CustomTooltip = withStyles({
    tooltip: {
      color: "white",
      fontSize: "13px",
    },
  })(Tooltip);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#0b0b27",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#06001b",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableHeader = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#06001b",
    },
  }));
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "#282454",
      border: "1px solid #ced4da",
      color: "white",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));
  const classes = useStyles();

  const getallchallenges = async (message = false) => {
    setLoading(true);
    const res = await axios.get(
      `/api/auth/getallchallenge?search=${search}&status=${filter}&fromAdmin=true`
    );
    setAllchallenge(res.data);
    setLoading(false);
    if (message) {
      successToast(message);
    }
  };
  useEffect(() => {
    if (tokenid == null) {
      navigate("/");
    }
    getallchallenges();
  }, [filter]);
  return (
    <>
      <ToastContainer />
      <div className="challenge_content">
        <div className="user_slide">
          <Admin />
        </div>
        <div className="challengetable container-fluid ">
          <div className="challenge-heading ">
            <h1>Challenge Details</h1>
          </div>
          {loading ? (
            <div className="loader-hight">
              <Loader />
            </div>
          ) : (
            // <div className="scrolltable">
            //   <table
            //     style={{ color: "white" }}
            //     className="table table-bordered table-dark table-striped aghhaa"
            //   >
            //     <thead style={{ textAlign: "center" }}>
            //       <tr>
            //         {/* <th>Category</th> */}
            //         <th>Challenger</th>
            //         <th>Reciever</th>
            //         <th>Challenger Terms</th>
            //         <th>Reciever Terms</th>
            //         <th>challenger Game of choice</th>
            //         <th>Reciever Game of choice</th>
            //         <th>Challenge Status</th>
            //         <th>Winner</th>
            //         <th>Loser</th>
            //         <th>Game Link</th>
            //         {/* <th>Reciever Link</th> */}
            //       </tr>
            //     </thead>

            //     <tbody>
            //       {allchallenge.map((items, index) => {
            //         return (
            //           <tr
            //             onClick={() =>
            //               navigate(`/challengedetails/${items?._id}`)
            //             }
            //           >
            //             {/* <td>{items?.category}</td> */}
            //             <td>{items?.player_1?.[0]?.name}</td>
            //             <td>{items?.player_2?.[0]?.name}</td>
            //             <td>{items?.player_1?.[0]?.text}</td>
            //             <td>
            //               {!items?.player_2?.[0]?.text
            //                 ? "no terms yet"
            //                 : items?.player_2?.[0]?.text}
            //             </td>
            //             <td>{items?.player_1?.[0]?.gamechoice}</td>
            //             <td>
            //               {!items?.player_1?.[0]?.gamechoice
            //                 ? "no game chosen yet"
            //                 : items?.player_2?.[0]?.gamechoice}
            //             </td>
            //             {items?.Accept === "true" &&
            //             items?.result === "pending" ? (
            //               <td>Accepted</td>
            //             ) : items?.Accept === "decline" ? (
            //               <td>Declined</td>
            //             ) : items?.Accept === "pending" ? (
            //               <td>Pending</td>
            //             ) : (
            //               <td>Declared</td>
            //             )}
            //             <td>
            //               {items?.winner === items?.player_1_id
            //                 ? items?.player_1?.[0]?.name
            //                 : items?.player_2?.[0]?.name}
            //             </td>
            //             <td>
            // {items?.loser === items?.player_1_id
            //   ? items?.player_1?.[0]?.name
            //   : items?.player_2?.[0]?.name}
            //             </td>
            //             <td>{items?.player_1?.[0]?.link}</td>
            //           </tr>
            //         );
            //       })}
            //     </tbody>
            //   </table>
            // </div>
            <div className="scrolltable">
              <div className="search-bar-container-admin">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Challenge Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={filter}
                    label="Age"
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                    className="mui-select"
                    style={{ width: "150px", fontSize: "15px" }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value={"pending"}>Pending</MenuItem>
                    <MenuItem value={"accepted"}>Accepted</MenuItem>
                    <MenuItem value={"decline"}>Declined</MenuItem>
                    <MenuItem value={"declared"}>Result declared</MenuItem>
                    <MenuItem value={"manual"}>Manual review</MenuItem>
                    <MenuItem value={"abanded"}>Abandoned</MenuItem>
                    <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                  </Select>
                </FormControl>
                <div style={{ marginTop: "14px" }}>
                  <Searchbar
                    search={setSearch}
                    submit={getallchallenges}
                    value={search}
                  />
                </div>
              </div>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead order={order} orderBy={orderBy}>
                    <StyledTableHeader>
                      <TableCell className={classes.ts}>Challenger</TableCell>
                      <TableCell className={classes.ts}>Reciever</TableCell>
                      <TableCell className={classes.ts}>Challenge No</TableCell>
                      <TableCell className={classes.ts}>
                        Challenge status
                      </TableCell>
                      <TableCell className={classes.ts} align="center">Winner</TableCell>
                      <TableCell className={classes.ts} align="center">Loser</TableCell>
                      <TableCell className={classes.ts} align="center">
                        Actions
                      </TableCell>
                      {/* <TableCell className={classes.ts} align="left"><TableSortLabel
              active={orderBy === "username"}
              direction={orderBy === "username" ? order : "asc"}
              onClick={createSortHandler("username")}
            >
              User
              {orderBy === "username" ? (
                <Box component="span" >
                  {order === 'asc' ? <ArrowUpwardIcon/> : <ArrowDownwardIcon/>}
                </Box>
              ) : null}
            </TableSortLabel></TableCell> */}
                    </StyledTableHeader>
                  </TableHead>
                  <TableBody>
                    {allchallenge
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((items, index) => (
                        <StyledTableRow
                          key={items?.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          onDoubleClick={() => handleModalOpen(items)}
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell className={classes.ts} align="left">
                            {items?.player_1?.[0]?.userId?.username}
                          </TableCell>
                          <TableCell className={classes.ts} align="left">
                            {items?.player_2?.[0]?.userId?.username}
                          </TableCell>
                          <TableCell className={classes.ts} align="left">
                            {items?.challenge_number}
                          </TableCell>
                          <TableCell className={classes.ts} align="left">
                            {items?.Accept === "true" &&
                            items?.result === "pending" ? (
                              <td>Accepted</td>
                            ) : items?.Accept === "decline" ? (
                              <td>Declined</td>
                            ) : items?.Accept === "pending" ? (
                              <td>Pending</td>
                            ) : items?.Accept === "abanded" ? (
                              <td>Abandoned</td>
                            ) : items?.Accept === "cancelled" ? (
                              <td>Cancelled</td>
                            ) : items?.Accept === "true" &&
                              items?.result === "Manual Review" ? (
                              <td>Manual review</td>
                            ) : (
                              <td>Result declared</td>
                            )}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                            {" "}
                            {items?.result != "declare" ? '-' : items?.winner === items?.player_1_id
                              ? items?.player_1?.[0]?.name
                              : items?.player_2?.[0]?.name}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                            {items?.result != "declare" ? '-' : items?.loser === items?.player_1_id
                              ? items?.player_1?.[0]?.name
                              : items?.player_2?.[0]?.name}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                            <CustomTooltip
                              title="View Challenge"
                              arrow
                              placement="top"
                              className={classes.tooltipClass}
                            >
                              <Button
                                className="none-bg"
                                onClick={() => handleModalOpen(items)}
                              >
                                <i
                                  className="far fa-eye"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </CustomTooltip>
                            {items?.result === "Manual Review" && (
                              <CustomTooltip
                                title="Manual review"
                                arrow
                                placement="top"
                                className={classes.tooltipClass}
                              >
                                <Button
                                  className="none-bg"
                                  onClick={() => handleModalOpen(items, true)}
                                >
                                  <i
                                    className="far fa-edit"
                                    aria-hidden="true"
                                  ></i>
                                </Button>
                              </CustomTooltip>
                            )}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allchallenge?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  MenuProps: { classes: { paper: classes.selectDropdown } },
                }}
                classes={{ menuItem: classes.menuItem }}
                className={classes.ts}
              />
            </div>
          )}
        </div>
        <EditChallengeModal
          challenge={editChallengeData}
          getChallenges={getallchallenges}
          show={show}
          handleModalOpen={handleModalOpen}
          handleModalClose={handleModalClose}
          isManual={isManual}
        />
      </div>
    </>
  );
};
