import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminlogin } from "../actions/userAction";
import { useAlert } from "react-alert";
import { Loader } from "../component/Loader";
import axios from "axios";
import styles from "./AdminLogin.module.css";
import logo from "../images/Logo.png";
import { errorToast } from "../constants/ToastNotifications";
export const AdminLogin = () => {
  const [tokenid, setTokenid] = useState("");
  const { isAuthenticated, user, error, loading } = useSelector(
    (state) => state.user
  );
  const [inputdata, setInputData] = useState({ email: "", password: "" });
  //   const alert = useAlert();
  const navigate = useNavigate();

  const handleChange = ({ currentTarget: input }) => {
    setInputData({ ...inputdata, [input.name]: input.value });

    console.log(inputdata);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .post(`/api/auth/adminlogin`, inputdata, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        console.log(res.data.token);
        setTokenid(res.data.token);
        localStorage.setItem("nftadmintoken", JSON.stringify(res.data.token));
        setInputData({
          email: "",
          password: "",
        });
        navigate("/admin/users");
      })
      .catch((error) => {
        if (error.response.status == 500) {
          errorToast("Invalid Email or Passsword");
        }
      });
  };

  return (
    <>
      <div className={`body-main ${styles.admin_container}`}>
        <div className="login-sec">
          <Container>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Row md={6}>
                  <div className={styles.login_container}>
                    <div className={styles.login_form_container}>
                      <div className={styles.left}>
                        <form
                          className={styles.form_container}
                          onSubmit={handleSubmit}
                        >
                          {/* <h2 className={styles.main_header}>Admin Login</h2> */}
                          <Link className="" to="/">
                            <img
                              className={styles.admin_logo}
                              alt="logo"
                              src={logo}
                              width="250"
                              style={{ marginBottom: "35px" }}
                            />
                          </Link>
                          <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                            value={inputdata.email}
                            required
                            className={styles.input}
                            autoComplete="email"
                          />
                          <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                            value={inputdata.password}
                            required
                            className={styles.input}
                          />

                          <button type="submit" className={styles.green_btn}>
                            Login
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </Row>
              </>
            )}
          </Container>
        </div>
      </div>
    </>
  );
};
