import Accordion from "react-bootstrap/Accordion";
import { Searchbar } from "../component/Searchbar";

import "./Faq.css";
import faq from "../images/faq.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
const Faqs = [
  {
    question: `What is GameWagrs?`,
    answer: <p>GameWagrs is a skill-based challenge wagering platform that allows you to win money from playing your favorite video games. Currently, we support Rocket League for now, and many more games to come (Call of Duty, Apex Legends, Valorant, Street Fighter V, Madden, NBA 2K & more!)</p>,
  },
  {
    question: `How can I learn more about GameWagrs and stay up to date?`,
    answer: <p>Join our <a href="https://discord.com/invite/TzHGjNyACC">GameWagrs Discord</a>  or follow our GameWagers socials!</p>,
  },
  {
    question: `Is GameWagrs Legal?
`,
    answer: <p>Yes, but only in locations where skill-based competitions are permitted. If you are in a jurisdiction where skill-based competitions are not allowed, you cannot utilize GameWagrs.</p>,
  },
  {
    question: `How old do you have to be to use GameWagrs?
`,
    answer: <p>You must be 18 years of age or older to engage in monetary gameplay on GameWagrs.</p>,
  },
  {
    question: `What countries does GameWagrs support?
`,
    answer: <p>GameWagrs is presently accessible in the following countries: 
      
      <ul className="countries">
      <li>United States (excluding Arizona, Arkansas, Louisiana, Montana, & South Dakota)</li>
      <li>Canada</li>
      <li>Mexico</li>
      <li>Ireland</li>
      <li>Germany</li>
      <li>France</li>
      <li>Spain</li>
      <li>Portugal</li>
      <li>Netherlands</li>
      <li>Austria</li>
      <li>Denmark</li>
      <li>Switzerland</li>
      <li>Norway</li>
      <li>Poland</li>
      <li>Finland</li>
      <li>United Kingdom</li>
      </ul>
      We have upcoming plans to expand to additional countries in Europe and LATAM in the near future.
      
      </p>,
  },
  {
    question: `How do I sign up on GameWagrs?
`,
    answer: <p>Goto our website at <a href="https://gamewagrs.com/">GameWagrs.com</a> and click the “Sign In” button at the top, and underneath the “Login” button you can then click the “Sign Up” button. Follow the instructions from there. Currently this platform is setup to work only on PC.
    GameWagrs is working on a mobile version of the platform for iOS and Android users.</p>,
  },
  {
    question: `How do I deposit funds to start challenging other users on GameWagrs?
`,
    answer: <p>After you have created an account with us, click on your avatar at the top and goto your profile. Select the “Deposit/Payment” tab, and choose your method of payment. Currently, we only allow Paypal deposits with plans to expand to other payment methods such as connecting your Venmo account and/or digital wallet (coming soon).</p>,
  },
  {
    question: `Why was my money held by PayPal?
`,
    answer:<> <p>GameWagrs may experience a temporary hold on your funds by PayPal due to the payment method you used. Typically, this hold lasts for about 3-5 business days. Once the funds have been cleared, you will receive a notification, and they will become accessible in your GameWagrs wallet automatically.</p><p>To prevent encountering this issue in the future, please ensure that you use either your PayPal balance, debit card, or credit card linked to both PayPal and GameWagrs.
   </p><p>Please note that even PayPal balance deposits may be subject to holds if there are any suspicions of fraudulent activity.</p><p> If you have any further questions, it is advisable to contact PayPal support for assistance.</p></>,
  },
  {
    question: `How do I withdraw my money from my GameWagrs account?
`,
    answer:<><p>Ready to cash out your winnings? Click on your avatar at the top and goto your profile. Select the “Deposit/Payment” tab, and click the “Cash Out” button. From there you will verify your ID credentials and choose the amount you want to cash out and confirm.</p><p>You will receive a notification on the platform once your withdrawal has cleared on the notifications page of your profile.</p></>,
  },
  {
    question: `How long do withdrawals take to process?
`,
    answer: <p>GameWagrs withdrawals are processed instantly within our system</p>,
  },
  {
    question: `How do I verify my PayPal account to withdraw money from GameWagrs?
`,
    answer: <p>In order to verify your PayPal account you need to link a bank account to your PayPal account.
    Tap <a href="https://www.paypal.com/us/cshelp/article/how-do-i-verify-my-paypal-account-faq444?$web_only=true&_branch_match_id=1149145549645509788&utm_medium=marketing&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXNywz1MtN1U/VL0stykyrBAAsr4rwFwAAAA==">here</a> to learn how to verify your PayPal.</p>,
  },
  {
    question: `Why should I trust GameWagrs?
`,
    answer: <p>Here at GameWagrs, we have a 3-way verification system to detect honest gameplay. With our real-time game API, the results of the challenge are verified using the game's data, ensuring fairness and accuracy. You can compete with confidence, knowing that the outcomes are determined based on authentic gameplay information. We also use a concession system for the challengers once a game has been played, denoting one player the winner and one player the loser. Lastly, we also have a feature to upload the VOD challenge stream to our website where our administrators can review any disputes post match to determine the winner. We provide a secure escrow service that safeguards your wager during gameplay, ensuring its safety until the transactions are completed.</p>,
  },
  {
    question: `What kind of fees can I expect on GameWagrs?
`,
    answer: <><p>On GameWagrs, there is a fee structure in place for challenges and wagers. For all wagers up to $100, a flat fee of $2 per challenge applies. However, for wagers exceeding $100, a 2% fee is charged on the total wager amount. Please note that these fees are subject to change and may vary based on specific circumstances.</p></>,
  },
  {
    question: `Can I have more than one account with GameWagrs?
`,
    answer: <p>To ensure fairness and prevent any competitive advantages within the platform, GameWagrs enforces a policy of allowing only one account per user. Multiple accounts per person are prohibited. This policy helps maintain a level playing field for all participants.</p>,
  },
  {
    question: `How do I link my Overwolf account with GameWagrs?
`,
    answer: <><p>After you have created an account with us, click on your avatar at the top and goto your profile. Select the “Linked Accounts” tab. Click on the “Connect Overwolf” button to link your GameWagrs account with Overworlf, and that’s it!</p><p>You can also connect your GameWagrs account to Xbox, Steam, Playstation, and Twitch. In the near future we plan to add Kick and YouTube linked accounts (coming soon).</p></>,
  },
  {
    question: `What is the minimum number of games required to play on GameWagrs for a particular game?
`,
    answer: <p>In order to participate in GameWagrs challenges, it is a requirement to have a minimum of 200 matches played in the specific game. This criterion is implemented with the intention of safeguarding you from potential cheaters and ensuring a secure gaming environment.</p>,
  },
  {
    question: `Do I have to stream in order to challenge someone on GameWagrs?
`,
    answer: <><p>Adding a stream link is optional, but please be aware that doing so is at your own risk. While it is not mandatory to include a stream link, we highly recommend it for transparency and fair play. Not providing a stream link may affect the ability to verify the match and can potentially impact the outcome. Please consider the potential implications before deciding whether or not to include a stream link. It is important to note that any user found abusing this rule will be subject to a ban from GameWagrs.</p><p>At this time, we currently accept stream links from Twitch, Kick, YouTube and Facebook streaming.</p></>,
  },
  {
    question: `What is a challenge on GameWagrs? How does it work?
`,
    answer: <><p>A challenge on GameWagrs entails players competing in a video game for money. Participants contribute an equal amount of money, and the winner of the game receives the entire pot, with applicable fees deducted.</p>
    <p>For example, let's say you create a $10 1v1 match in Rocket League, Best of 3 Games on GameWagrs. Your opponent would also contribute $10, resulting in a $20 pot. Based on the GameWagrs fee structure, a flat fee of $2 per challenge applies to challenges up to $10. In this scenario, the fee would be deducted from the total pot, leaving $18 to be awarded to the winner.</p>
    <p>On GameWagrs, you can create a challenge match by selecting the 'Challenge Someone' tab at the top of the website, and then specifying the game of choice, terms, whether live stream is required or not, and the desired amount of money for the challenge. Throughout the gameplay, we securely hold the funds from both sides in our escrow account, ensuring fairness and safety. Once the match concludes, we automatically transfer the adjusted prize amount to the winner, after deducting the applicable fees.</p></>,
  },
  {
    question: `How do GameWagrs Referral Tokens work (coming soon)?
`,
    answer:<><p>Share your referral link!<br/>
    Send your link to a friend who's interested in GameWagrs.</p>
    <p>Have your friend sign up!<br/>
Make sure they sign up using your referral link.</p>
    <p>Get a bonus!<br/>
After your friend completes their first challenge you and your friend will get a GameWagrs Referral Token to use on your next challenge which allows you to submit a challenge with NO FEES!</p>
    <p>Referral Rewards Program Conditions</p>
    <p>The conditions of the referral program are as follows:
      <ul className="referral">
      <li>Your friend will get their GameWagrs Referral Token only when they use your referral link (or enter your referral code) when they sign up.</li>
      <li>You will receive your GameWagrs Referral Token after your friend has completed their first successful challenge.</li>
      </ul>
      </p>
    <p>GameWagrs Referral Tokens are automatically applied to your next challenge</p>
    <p>GameWagrs Referral Tokens can only be used one at a time and cannot be combined in any way.</p>
    <p>GameWagrs reserves the right to provide & revoke bonuses from users at any time.</p>
   
    </>,
  },
  {
    question: `What is a challenge dispute on GameWagrs?
`,
    answer: <p>A dispute on GameWagrs arises when you and your opponent have a concessions disagreement regarding the outcome or events that occurred during the match. This can happen when both players claim victory or when one player accuses the other of breaking the rules, such as using cheats or utilizing banned items.</p>,
  },
  {
    question: `When will my challenge dispute be reviewed by GameWagrs administrators?
`,
    answer: <p>Challenge disputes on GameWagrs are typically reviewed within a timeframe of 24-48 hours. We strive to address and resolve disputes as promptly as possible. If your dispute has not been addressed within 72 hours, we kindly ask you to reach out to us directly on our <a href="https://discord.com/invite/TzHGjNyACC">GameWagrs Discord</a> or at <a href="mailto:administrator@gamewagrs.com">administrator@gamewagrs.com.</a></p>,
  },
  {
    question: `What happens if I disagree with the administrator’s challenge dispute decision?
`,
    answer: <p>We kindly ask you to reach out to us directly on our <a href="https://discord.com/invite/TzHGjNyACC">GameWagrs Discord</a> or at <a href="mailto:administrator@gamewagrs.com">administrator@gamewagrs.com.</a> </p>,
  },
  {
    question: `How do I get in contact with GameWagrs support?
`,
    answer: <p>We kindly ask you to reach out to us directly on our  <a href="https://discord.com/invite/TzHGjNyACC">GameWagrs Discord</a> or at <a href="mailto:administrator@gamewagrs.com">administrator@gamewagrs.com.</a> </p>,
  },
//   {
//     question: `(HIDE) How do kill race challenges work?
// `,
//     answer:<> <p>WARZONE -
//       <br/>
//       <ol>
//         <li>Enter as many public Warzone games as you want with your team during your tournament's allotted time period.</li>
//         <li>Every kill you get is 1 point and additional points are awarded based on how well your team places in that game.</li>
//         <li>We take the scores (kills + placement points) of your best 3 games to determine your total score.</li>
//         <li>The teams with the highest scores win!</li>
//       </ol>
//     </p>
//     <p>APEX LEGENDS -</p>
//     <br/>
//     <ol>
//       <li>Enter as many public Apex games as you want with your team during your tournament's allotted time period.</li>
//       <li>Every kill you get is 1 point and additional points are awarded based on how well your team places in that game.</li>
//       <li>We take the scores (kills + placement points) of your best 3 games to determine your total score.</li>
//       <li>The teams with the highest scores win!</li>
//     </ol>
//     </>,
//   },

];
const Faq = () => {
  const [faqState,setFaqState] = useState(Faqs)
  const [search,setSearch] = useState('')
  const searchData = ()=>{
    if(search){
      const newState = Faqs.filter((item)=>item.question.toLowerCase().includes(search.toLowerCase()))
      setFaqState(newState)
    }else{
      setFaqState(Faqs)
    }
  }
  return (
    <div className="body-main">
      <div className="faq">
        <div className="faq-container">
          {/* <div className='section-title'>
              <h2>FAQs</h2>
          </div> */}
          <div className="faq-main">
            <div className="right-faq">
              <div className="right-faq-main">
                <div className="right-faq-head">GameWagrs FAQs</div>
                <div className="right-faq-body">
                  If you are new to Gamewagers this FAQs will help you learn more
                  about the platform and it's features.
                </div>
                <div className="right-faq-body-2">
                  For more information you can always try to reach us on 
                  <a href="https://gamewagrs.com/contact-us" className="contact-link"> CONTACT FORM</a>
                </div>
                <div className="right-faq-body-3">
                  <Searchbar  
                  search={setSearch}
                  submit={searchData}
                  value={search}/>
                </div>
              </div>
              <div className="image-container">
                <img src={faq} className="faq-svg" alt="FAQs"/>
              </div>
            </div>
            <div className="left-faq">
              <Accordion defaultActiveKey='0'>
                {faqState.map((item,index)=>{
                  return <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                   {item.question}
                  </Accordion.Header>
                  <Accordion.Body>
                    {item.answer}
                  </Accordion.Body>
                </Accordion.Item>
                })}
                
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Faq;
