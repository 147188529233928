import axios from "axios";
import moment from "moment";

import EditUserModal from "./EditUserModal";
import Tooltip from "@material-ui/core/Tooltip";

import { Loader } from "../component/Loader";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Admin } from "./Admin";
import { Searchbar } from "../component/Searchbar";

import "./Table_user.css";
import { Button } from "react-bootstrap";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { Avatar } from "@material-ui/core";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export const Table_user = () => {
  const navigate = useNavigate();
  const [usercount, setUserCount] = useState();
  const [userdata, setuserdata] = useState([]);
  const [allchallenge, setAllchallenge] = useState([]);
  const [tokenid, setTokenid] = useState(localStorage.getItem("nftadmintoken"));
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [editUserData, setEditUserData] = useState({});

  // Table sorting and pagination

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModalOpen = (userData) => {
    setEditUserData(userData);
    setShow(true);
  };
  const handleModalClose = () => {
    setEditUserData({});
    setShow(false);
  };
  const getuserdata = async () => {
    setLoading(true);
    const res = await axios.post(
      `/api/auth/getuserdata?search=${search}&fromAdmin=true`
    );
    setuserdata(res.data);
    setUserCount(res.data.length);
    setLoading(false);
  };

  // MUI styles override

  const useStyles = makeStyles({
    ts: {
      color: "#ffffff",
      fontSize: `${window.innerWidth > 400 ? "15px" : "13px"}`,
      fontFamily: "Roboto,sans-serif",
      "&:hover": {
        color: "#ffffff",
      },
      "&:active": {
        color: "#ffffff",
      },
      "&:focus": {
        color: "#ffffff",
      },
    },
    selectDropdown: { color: "#fff", backgroundColor: "#0b0b27" },
    menuItem: {
      "&:hover": {
        backgroundColor: "#06001b",
      },
      select: {
        color: "red",
        background: "white",
      },
    },
    owaccountstatussuccess :{
      color:'green',
      fontWeight:'bold'
    },
    owaccountstatuspending :{
      color:'grey',
      fontWeight:'bold'
    }
  });

  const CustomTooltip = withStyles({
    tooltip: {
      color: "white",
      fontSize: "13px",
    },
  })(Tooltip);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#0b0b27",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#06001b",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableHeader = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#06001b",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (tokenid == null) {
      navigate("/");
    }

    getuserdata();
  }, []);
  return (
    <>
      <div className="user_content">
        <div className="user_slide">
          <Admin />
        </div>
        <div className="container-fluid  table_A">
          <div className="challenge-heading">
            <h1>User Details</h1>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="media_scroll">
              <div className="search-bar-container-admin">
                <Searchbar
                  search={setSearch}
                  submit={getuserdata}
                  value={search}
                />
              </div>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead order={order} orderBy={orderBy}>
                    <StyledTableHeader>
                      <TableCell className={classes.ts}>#</TableCell>
                      <TableCell className={classes.ts} align="left">
                        <TableSortLabel
                          active={orderBy === "username"}
                          direction={orderBy === "username" ? order : "asc"}
                          onClick={createSortHandler("username")}
                        >
                          User
                          {orderBy === "username" ? (
                            <Box component="span">
                              {order === "asc" ? (
                                <ArrowUpwardIcon />
                              ) : (
                                <ArrowDownwardIcon />
                              )}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.ts} align="left">
                        <TableSortLabel
                          active={orderBy === "email"}
                          direction={orderBy === "email" ? order : "asc"}
                          onClick={createSortHandler("email")}
                        >
                          Email
                          {orderBy === "email" ? (
                            <Box component="span">
                              {order === "asc" ? (
                                <ArrowUpwardIcon />
                              ) : (
                                <ArrowDownwardIcon />
                              )}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        className={classes.ts}
                        align="center"
                      >
                        OW Account Linking
                      </TableCell>
                      <TableCell
                        className={classes.ts}
                        align="center"
                      >
                        {" "}
                        OW Account Name
                      </TableCell> 
                      <TableCell
                        className={classes.ts}
                        align="center"
                      >
                        {" "}
                        OW Account Email
                      </TableCell>
                      <TableCell
                        className={classes.ts}
                        align="center"
                      >
                        {" "}
                        Epic Username
                      </TableCell>
                      <TableCell className={classes.ts} align="center">
                        <TableSortLabel
                          active={orderBy === "created_at"}
                          direction={orderBy === "created_at" ? order : "asc"}
                          onClick={createSortHandler("created_at")}
                        >
                          Signup date
                          {orderBy === "created_at" ? (
                            <Box component="span">
                              {order === "asc" ? (
                                <ArrowUpwardIcon />
                              ) : (
                                <ArrowDownwardIcon />
                              )}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.ts} align="center">
                        Verified
                      </TableCell>
                      <TableCell className={classes.ts} align="center">
                        Actions
                      </TableCell>
                    </StyledTableHeader>
                  </TableHead>
                  <TableBody>
                    {stableSort(userdata, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((items, index) => (
                        <StyledTableRow
                          key={items.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Avatar  className='user-avatar' src={`${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${items.avatar}`} />
                          </TableCell>
                          <TableCell className={classes.ts} align="left">
                            {items.username}
                          </TableCell>
                          <TableCell className={classes.ts} align="left">
                            {items.email}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items?.overwolf ? <span className={classes.owaccountstatussuccess}>Successful</span> : <span className={classes.owaccountstatuspending}>Pending</span>} 
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items?.overwolf?.preferred_username ? items?.overwolf?.preferred_username :'-'} 
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items?.overwolf?.email ? items?.overwolf?.email : "-" }
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                          {items?.userepicname ? items?.userepicname : "-"} 
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                            {moment(items.created_at).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell className={classes.ts} align="center">
                            {items.verified_at ? "Yes" : "No"}
                          </TableCell>

                          <TableCell className={classes.ts} align="center">
                            <CustomTooltip
                              title="Edit user"
                              arrow
                              placement="top"
                              className={classes.tooltipClass}
                            >
                              <Button
                                className="none-bg"
                                onClick={() => handleModalOpen(items)}
                              >
                                <i
                                  className="far fa-edit"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                            </CustomTooltip>
                          </TableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                id="allusers"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={userdata.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" , "id":"allusers"},
                  MenuProps: { classes: { paper: classes.selectDropdown } },
                }}
                classes={{ menuItem: classes.menuItem, input: classes.select }}
                className={classes.ts}
              />
            </div>
          )}
        </div>
        <EditUserModal
          show={show}
          handleModalOpen={handleModalOpen}
          handleModalClose={handleModalClose}
          userData={editUserData}
          getUserData={getuserdata}
        />
      </div>
    </>
  );
};
